.widget-image-text {
  @include last-p-no-margin;

  &__text-absolute {
    @include section-absolute-content;

    @include mq-m-max {
      width: calc(100% + #{$padding-base-section});
    }
  }

  &__content {
    @include mq-s-max() {
      padding: 0;
    }

    @include last-p-no-margin;
    padding: 0 $padding-reduced;
  }

  &__text-container {
    @include mq-s-max() {
      padding: 0;
    }

    padding: 0 calc($padding-reduced * 5);
  }

  &--narrow {
    .widget-image .widget-image__media,
    .media {
      @include mq-s-max() {
        @include full-width-mobile-image;
      }
    }
    margin-top: $padding-base-section-content;

    .widget-image-text__text-container {
      padding-top: $padding-base-section-content;
    }
  }

  &--menu {
    @include mq-s-max() {
      margin-bottom: 0;
    }
    // menu margin + half menu line-height
    margin-bottom: -($padding-reduced + 18px);
  }

  .btn--primary {
    @include mq-s-max() {
      width: 100%;
    }
  }

  // Image text moidifier "Teaser"
  &--teaser &__title {
    @include teaser-title;
  }

  &--left {
    @include mq-s-max() {
      order: 2;
    }
  }
}

.widget-image__subtitle {
  @include font-size(14);
  line-height: 1.2;
  display: block;
  color: $limmi-blue;
  font-weight: $demibold;
  text-transform: uppercase;
}

.show-more-container {
  width: 100%;
  display: block;
  padding: 0 $padding-reduced-half;

  a {
    font-family: $primary-font;
  }

  .arrow-down {
    @extend .icon-arrow-down;
    @include i-icon-font();
    vertical-align: text-bottom;

    &:before {
      transition: all 0.2s linear;
    }

    &.active {
      &:before {
        transform: rotate(180deg);
      }
    }
  }

  .widget-image__description {
    display: none;

    &.active {
      display: block;
    }
  }

  &.image-left {
    .widget-image-text--image-before {
      float: left;
      margin-right: $padding-percentage-base;
    }

    .widget-image-text--show-more {
      margin-right: $padding-percentage-base;
    }
  }

  &.image-right {
    .widget-image-text--image-before {
      float: right;
      margin-left: $padding-percentage-base;
    }

    .widget-image-text--show-more {
      margin-left: $padding-percentage-base;
    }
  }
}

.widget-image__media + .widget-image__text-container,
.widget-image__media + .widget-image-text__content,
.media + .widget-image__text-container,
.media + .widget-image-text__content {
  @include mq-s-max() {
    margin-top: $padding-base-half;
  }

  margin-top: $padding-base;
}

.widget-image-text + .grid {
  margin-top: $padding-base-section-content;
}
