$post-image-height-desktop: 200px;
$post-image-height-tablet: 180px;
$post-image-height-mobile: 200px;
$post-image-height-small-mobile: 200px;

.instagram-plugin {
  &__title {
    margin-bottom: $gutter;
  }

  &__subtitle {
    margin-bottom: $gutter-double;
  }

  &__posts-container {
    @include mq-m-max() {
      grid-template-columns: repeat(4, 1fr);
    }
    @include mq-s-max() {
      grid-template-columns: repeat(2, 1fr);
    }
    @include mq-xs-max() {
      grid-template-columns: 1fr;
    }
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: $gutter;
  }

  &__button-container {
    @extend %centered-content;
    margin-top: $gutter-double;
  }

  &__post {
    display: none;

    &.active {
      display: block;
    }

    img {
      @include mq-m-max() {
        height: $post-image-height-tablet;
      }
      @include mq-s-max() {
        height: $post-image-height-mobile;
      }
      @include mq-xs-max() {
        height: $post-image-height-small-mobile;
      }
      height: $post-image-height-desktop;
      object-fit: cover;
      width: 100%;
    }
  }
}
