$form-field-size: 570px;

button,
select,
option,
label,
[type="submit"],
[type="button"],
[type="radio"],
[type="checkbox"] {
  @include font-size(18);
  cursor: pointer;
  font-family: $primary-font;
  font-weight: normal;
  padding: $padding-reduced-half;
  vertical-align: middle;
}

input,
textarea,
select {
  font-family: $secondary-font;
  font-weight: normal;
  background: $white;
  border-radius: 3px;

  &:focus {
    border-color: $title-grey;
  }
}

[type="text"],
[type="search"],
[type="file"],
[type="number"],
[type="email"],
[type="password"],
textarea {
  @include form-text;
  border: 1px solid $border-grey;
  width: 100%;
  height: 50px;
  padding: $padding-reduced-half $padding-reduced;

  &:focus {
    border-color: $title-grey;
  }
}

textarea {
  height: auto;
  min-height: 200px;
  resize: vertical;
}

label {
  @include form-label;
  display: inline-block;
  padding: 0;
}

button {
  @include font-size(18);
  margin: 0;
  border: 0;
  padding: 0;
}

select {
  @include font-size(18);
  width: 100%;

  &:focus {
    border-color: $black;
  }
}

// sass-lint:disable id-name-format no-ids
#mc_embed_signup {
  padding-top: $padding-reduced;

  #mc-embedded-subscribe {
    @include font-size(22);
  }

  .mc-field-group {
    width: 25%;
    padding-bottom: $padding-reduced;
    margin: 0 auto;

    @media screen and (max-width: $screen-m) {
      width: 50%;
    }

    @media screen and (max-width: $screen-s) {
      width: 70%;
    }
  }
}
// sass-lint:enable id-name-format no-ids

.form-field {
  @include mq-s-max() {
    width: 100%;
  }

  margin-top: $padding-reduced;
  width: $form-field-size;

  &:first-of-type {
    @include mq-s-max() {
      margin-top: $padding-reduced;
    }

    margin-top: $padding-base-section;
  }

  &--error {
    label {
      color: $red;
    }

    input,
    textarea {
      border: 1px solid $red;
      color: $red;
    }
  }

  &--submit {
    @include mq-s-max {
      margin-top: $padding-reduced-half;
    }

    margin-top: $padding-reduced-double;
  }

  &.date-of-birth {
    .control-field {
      display: flex;
      justify-content: space-between;

      select {
        flex: 0 0 30%;
      }
    }
  }
}

.errorlist {
  @include font-size(14);
  list-style-type: none;
  color: $red;
}

.radio {
  @include mq-s-max() {
    width: 50%;
  }

  display: inline-block;

  + .radio {
    @include mq-s-max() {
      margin-left: 0;
    }

    margin-left: $gutter;
  }

  input {
    &[type="radio"] {
      &:checked,
      &:not(:checked) {
        position: absolute;
        left: -9999px;
      }

      &:checked + label,
      &:not(:checked) + label {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        margin-top: 0;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: $padding-reduced;
          height: $padding-reduced;
          border: 1px solid #ddd;
          border-radius: 100%;
          background: $white;
        }

        &:after {
          content: "";
          width: $padding-reduced;
          height: $padding-reduced;
          left: 0;
          top: 0;
          position: absolute;
          border-radius: 100%;
          box-shadow: inset 0 0 0 5px $limmi-blue;
          transition: all 0.2s ease;
        }
      }

      &:not(:checked) + label:after {
        opacity: 0;
        transform: scale(0);
      }

      &:checked + label:after {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}

.form-base {
  @include mq-s-max() {
    padding: 0;
  }

  margin: 0 $gutter-half; // we need to simulate the columns
  width: 100%;
  border-top: 1px solid $border-grey;
  padding: $padding-reduced-double $padding-percentage-base 0 $padding-percentage-base;

  abbr {
    text-decoration: none;
  }

  .form-group {
    @include mq-s-max() {
      max-width: 100%;
    }

    max-width: 65%;

    &.half {
      display: inline-block;
      margin-right: 5%;
      width: 15%;

      ~ .half {
        @include mq-s-max() {
          width: 100%;
        }

        width: 45%;
      }

      @include mq-s-max() {
        display: block;
        width: 100%;
        margin-right: 0;
      }
    }
  }

  fieldset {
    // Padding is assigned to legend (much like a header) to avoid too much spacing
    // when a titleless fieldset is created
    // Margin was not working here, using padding. Carlo
    legend {
      @include mq-s-max() {
        padding-top: $padding-reduced;
      }

      padding-top: $padding-reduced-double;
    }

    // The user must use this class to achieve an input text beside 3 checkboxes
    &.text-and-3-checkboxes {
      @include mq-s-max {
        width: 100%;
      }

      width: $form-field-size;

      .form-field {
        display: inline-block;
        width: 68%;

        &.checkbox-group {
          text-align: right;
          width: 30%;
          float: right;
          margin-top: 45px; // This is needed to align checkboxes vertically
        }

        @include mq-s-max {
          display: block;
          width: 100%;

          &.checkbox-group {
            text-align: left;
            width: 100%;
            float: none;
            margin-top: 0;
          }
        }
      }
    }
  }

  legend {
    @extend h4;
    margin: 0;
  }

  label {
    @include form-label;
    display: block;
    width: 100%;
    max-width: $form-field-size;

    span {
      @include mq-s-max() {
        font-weight: $regular;
      }

      @include font-size(14);
      margin-left: $padding-reduced-quarter;
    }

    @include mq-s-max() {
      @include font-size(14);
      font-weight: $bold;
      max-width: 100%;
      margin: $padding-reduced-half 0 0 0;
    }

    &.captcha-field-label {
      display: none;
    }
  }

  input {
    [type="text"] {
      height: 100%;
      max-height: 50px;
      padding: $padding-reduced-half;
      border: 1px solid $border-grey;
      border-radius: 3px;
      box-shadow: none;

      &:focus {
        border-color: $text-grey;
      }
    }
  }

  .has-error {
    color: $red;

    label {
      color: $red;
    }

    .control-field > [type="text"] {
      border-color: $red;
      color: $red;
    }
  }

  .text-danger {
    display: block;
  }

  .radio {
    @include mq-s-max() {
      width: 50%;
    }

    display: inline-block;
    width: 25%;

    input {
      &[type="radio"] {
        &:checked,
        &:not(:checked) {
          position: absolute;
          left: -9999px;
        }

        &:checked + label,
        &:not(:checked) + label {
          position: relative;
          padding-left: 28px;
          cursor: pointer;
          line-height: 20px;
          display: inline-block;
          margin-top: 0;

          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: $padding-reduced;
            height: $padding-reduced;
            border: 1px solid #ddd;
            border-radius: 100%;
            background: $white;
          }

          &:after {
            content: "";
            width: $padding-reduced;
            height: $padding-reduced;
            left: 0;
            top: 0;
            position: absolute;
            border-radius: 100%;
            box-shadow: inset 0 0 0 5px $limmi-blue;
            transition: all 0.2s ease;
          }
        }

        &:not(:checked) + label:after {
          opacity: 0;
          transform: scale(0);
        }

        &:checked + label:after {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }

  button {
    @include mq-s-max() {
      margin: 0;
    }

    display: block;
    margin: 0;
  }
}

.checkbox {
  @include mq-s-max() {
    width: auto;
    margin-right: $padding-base-half;
    margin-top: $padding-base-half;

    &:first-child {
      margin-top: 0 !important;
    }
  }

  display: inline-block;
  vertical-align: top;

  label {
    display: inline-block;
    width: auto;
  }

  + .checkbox {
    @include mq-s-max() {
      margin-left: 0;
      margin-right: $gutter-half;
    }

    margin-left: $gutter-half;
  }

  [type="checkbox"] {
    &:checked,
    &:not(:checked) {
      position: absolute;
      left: -9999px;
    }

    &:checked + label,
    &:not(:checked) + label {
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      margin-top: 0;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: $padding-reduced;
        height: $padding-reduced;
        border: 1px solid #ddd;
        background: $white;
      }

      &:after {
        content: "";
        width: $padding-reduced;
        height: $padding-reduced;
        left: 0;
        top: 0;
        position: absolute;
        box-shadow: inset 0 0 0 5px $limmi-blue;
        transition: all 0.2s ease;
      }
    }

    &:not(:checked) + label:after {
      opacity: 0;
      transform: scale(0);
    }

    &:checked + label:after {
      opacity: 1;
      transform: scale(1);
    }
  }
}

.form-base > .checkbox-group:first-of-type,
fieldset > .checkbox-group:first-of-type {
  margin-top: $padding-reduced;
}

@mixin checkbox-group--column {
  .checkbox {
    margin-left: 0;
    margin-right: $padding-reduced;
    margin-top: $padding-base-half;
  }
}

.checkbox-group--column-2 {
  @include checkbox-group--column;

  .checkbox {
    width: 45%;
  }
}

.checkbox-group--column-1 {
  @include checkbox-group--column;

  .checkbox {
    width: 90%;
  }
}

.form-counter,
.form-no-submissions-available {
  margin-bottom: $padding-base-half;
  text-align: center;

  h5 {
    color: $limmi-blue;
  }
}
