.link {
  &__stand-alone {
    @extend .sa-link;
  }

  &__weitere {
    display: block;
    background: url("../img/icons/pfeil_blau.png") no-repeat;
    height: $padding-reduced-triple + 6;
    width: $padding-reduced-triple + 6;
    border: 1px solid $limmi-blue;
    border-radius: 100%;
    pointer-events: none;

    a {
      display: block;
      text-indent: -9999em;
      text-transform: uppercase;
    }
  }

  &__text {
    text-decoration: underline;

    &:hover {
      color: $limmi-blue;
      text-decoration: none;
    }
  }
}
