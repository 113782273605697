.job {
  td,
  th,
  li {
    @include font-size(18);
    margin: $padding-reduced-half 0 $padding-reduced-half $padding-reduced;
    color: $text-grey;
    font-family: $secondary-font;
    font-weight: $regular;
    letter-spacing: 0.2px;
    line-height: 1;
  }

  td,
  th {
    line-height: 26px;
    padding-right: $padding-reduced;
  }

  th {
    font-weight: $medium;
  }

  li {
    list-style-type: disc;
  }
}
