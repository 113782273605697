.clinic-team {
  &__cta {
    @include mq-s-max() {
      padding: $padding-reduced 0;

      .btn--primary {
        width: 100%;
      }
    }

    padding: $padding-reduced-double $padding-reduced;
    text-align: center;
  }
}
