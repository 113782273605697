@mixin wysiwyg-icon($content) {
  &:before {
    content: $content;
    margin-right: 10px;
  }
}

@mixin checked-icon($icon-size: 18px) {
  display: block;
  padding-left: $icon-size + $padding-base-half;

  &:before {
    display: inline-block;
    height: $padding-reduced - 2px;
    width: $padding-reduced - 2px;
    margin-right: $padding-base-half;
    margin-left: -($icon-size + $padding-base-half);
    background: url("/static/img/icons/check.svg") no-repeat;
    background-size: contain;
    content: "";
    vertical-align: middle;
  }
}

.button__text {
  @include font-size(14);
  font-family: $secondary-font;
  font-weight: $bold;
  letter-spacing: 0.8px;
  text-transform: uppercase;

  &--blue {
    color: $limmi-blue;
  }

  &--white {
    color: $white;
  }
}

p {
  &.h2-like {
    @extend h2;
    letter-spacing: normal;
  }

  &.h3-like {
    @extend h3;
    letter-spacing: normal;
  }

  &.h4-like {
    @extend h4;
    letter-spacing: normal;
  }

  &.h5-like {
    @extend h5;
    letter-spacing: normal;
  }

  &.h6-like {
    @extend h6;
  }
}

span {
  &.text--evidence {
    @include mq-s-max() {
      @include font-size(32);
    }

    @include font-size(60);
    color: $limmi-blue;
  }

  &.text--big {
    @include mq-s-max() {
      @include font-size(32);
      line-height: 0; /* -- This fixes rendering inside the page -- */
    }

    @include font-size(80);
    color: $limmi-blue;
    font-weight: 300;
    line-height: 62px; /* -- This is needed for rendering in wysiwyg box -- */
  }

  // &.text--regular {

  // }

  &.teaser__title.teaser__title--red {
    @include font-size(18);
    color: $red;
    font-family: $secondary-font;
    font-weight: $medium;
    letter-spacing: 0.4px;
    line-height: 24px;
  }

  &.checked {
    @include checked-icon();
  }

  &.btn.btn--primary {
    @extend .button__text;
    margin: 0 auto;
    border-radius: 5px;
    // sass-lint:disable no-vendor-prefixes
    -webkit-appearance: none;
    // sass-lint:enable no-vendor-prefixes
    border: 0;
    cursor: pointer;
    display: inline-block;
    padding: $padding-reduced - 7 $padding-reduced + $padding-reduced-half;
    line-height: 21.5px;
    text-decoration: none;
    text-align: center;
    background: $limmi-blue;
    color: $white;
    transition: all 0.3s ease-in-out;
  }
}

// h3 {
//     &.title__section {

//     }

//     &.title__box {

//     }

//     &.title__accordion {

//     }
// }

i {
  &.sa-link {
    @include mq-s-max() {
      margin-top: $padding-reduced;
    }

    @include font-size(16);
    font-family: $primary-font;
    font-weight: $medium;
    letter-spacing: 0.4px;
    line-height: 20px;
    margin-top: $padding-reduced-double;

    a {
      color: $limmi-blue;

      &:hover {
        opacity: 0.5;
      }

      &:before {
        @include arrow-link($color: blue, $margin: 0 $padding-reduced-half 0 0);
      }
    }
  }

  &.sa-link-back {
    @include font-size(16);
    font-family: $primary-font;
    font-weight: $medium;
    letter-spacing: 0.4px;
    line-height: 20px;
    margin-top: $padding-reduced-double;

    a {
      color: $limmi-blue;

      &:hover {
        opacity: 0.5;
      }

      &:before {
        @include arrow-link($color: blue, $margin: 0 $padding-reduced-half 0 0, $flip: true);
      }
    }
  }

  &.sa-link-black {
    @include font-size(16);
    font-family: $primary-font;
    font-weight: $medium;
    letter-spacing: 0.4px;
    line-height: 20px;
    margin-top: $padding-reduced-double;
    color: $black;

    &:before {
      @include arrow-link($color: black, $margin: 0 $padding-reduced-half 0 0);
    }
  }

  &.sa-link-black-back {
    @include font-size(16);
    font-family: $primary-font;
    font-weight: $medium;
    letter-spacing: 0.4px;
    line-height: 20px;
    margin-top: $padding-reduced-double;
    color: $black;

    &:before {
      @include arrow-link($color: black, $margin: 0 $padding-reduced-half 0 0, $flip: true);
    }
  }

  &.phone-icon {
    @extend .icon-phone;
    @include i-icon-font();
  }

  &.email-icon {
    @extend .icon-envelope;
    @include i-icon-font();
  }

  &.calendar-icon {
    @extend .icon-calendar;
    @include i-icon-font();
  }

  &.location-pin-icon {
    @extend .icon-location-pin;
    @include i-icon-font();
  }

  &.clock-icon {
    @extend .icon-clock;
    @include i-icon-font();
  }

  &.fax-icon {
    @extend .icon-printer;
    @include i-icon-font();
  }

  &.share-alt-icon {
    @extend .icon-share-alt;
    @include i-icon-font();
  }

  &.globe-icon {
    @extend .icon-globe;
    @include i-icon-font();
  }
}

h1,
h2,
h3,
h4,
h6 {
  i:before {
    top: 0.2em !important;
  }
}

table {
  width: 60%;
}

td {
  @extend p;
  height: 35px;
  vertical-align: middle;
}

th {
  @extend p;
  height: 35px;
  font-weight: $medium;
  text-align: left;
}

// FIXME //
// Adding this class to get all wysiwyg's

header + .wysiwyg {
  margin-top: $padding-base-half + $line-height-correction-offset;
}

.wysiwyg {
  > * {
    margin: 0;
  }

  > * + * {
    @include mq-s-max() {
      margin-top: $padding-base-half;
    }

    margin-top: $padding-reduced;
  }

  // ADD HERE EXCEPTIONS TO THE MARGIN RESET ABOVE
  .footer__social-img + .footer__social-img {
    margin-left: 5px;
  }

  // Every link in the wysiwyg which is a direct child of a p without classes, has a special style
  p:not([class]) > a {
    font-family: $secondary-font;
    text-decoration: underline;
    color: $text-grey;

    &:visited {
      color: $text-grey;
    }

    &:hover {
      text-decoration: none;
      color: $limmi-blue;
    }
  }

  ul {
    @extend p;
    margin-left: $gutter-double;
    list-style: disc;

    ul {
      list-style: none;
      margin: 5px 0;

      li:before {
        content: "-";
        margin-right: $gutter-quarter;
      }
    }
  }

  ol {
    @extend p;
    margin-left: $gutter-double;
    list-style: decimal;
  }
}

// This reverts changes above for .widget-image__intro-menu which is a special wysiwyg
.widget-image__intro-menu.wysiwyg {
  ul {
    margin: 0;
    list-style: none;
  }
}

.page-container--adipositaszentrum {
  .wysiwyg {
    table {
      max-width: 100%;
      width: 100%;

      tr {
        border-top: $border $border-grey;

        th,
        td {
          padding: 10px 0;
          vertical-align: top;

          & + td,
          & + th {
            padding-left: 10px;
          }
        }
      }
    }
  }
}
