.breadcrumbs {
  @include mq-s-max() {
    padding: $padding-reduced - 5px $padding-reduced;
    margin-bottom: $padding-reduced - 5px;
  }

  position: relative;
  flex: 1 1 100%;
  // 15px because of the top header shadow
  padding: $padding-base-section + 15px $padding-reduced $padding-base-section;

  &__item {
    color: $title-grey;
    font-family: $primary-font;
    letter-spacing: 1px;

    &:visited {
      color: $title-grey;
    }

    &:hover {
      color: $limmi-blue;
    }
  }

  &__separator {
    padding: 0 $padding-reduced-half;
  }

  &:after {
    @include mq-s-max() {
      width: $padding-reduced-double;
    }

    position: absolute;
    height: 2px;
    width: 80px;
    background: $limmi-blue;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    content: "";
    opacity: 0.5;
  }
}
