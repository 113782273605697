.search-results {
  @include within-grid;
  @include mq-s-max() {
    margin-bottom: $padding-reduced-double;
  }

  .more-info {
    margin-top: $padding-reduced-double;
    margin-bottom: $padding-reduced-double;
  }
}

.search-result {
  @include rounded-container();
  @include rounded-container-1-col();
}
