$treatment-offer-offset: 150px;
$treatment-offer-gender-size: 60px;

.treatment-offers {
  @include mq-s-max() {
    overflow: hidden;
    padding-top: 0;
  }

  @include mq-m-max {
    padding-top: 0;
  }

  padding-top: $padding-base-section;
}

.treatment-offer {
  @include mq-s-max {
    margin-top: $padding-reduced;
  }

  @include mq-m-max {
    background-color: $white;
    margin-top: $padding-reduced;
  }

  @include mq-l {
    background-color: $light-blue;
  }

  background-color: $light-blue;
  margin-top: $treatment-offer-offset - $padding-reduced;

  &__header {
    @include mq-m-max {
      @include fullwidth-breaking-grid;
      transform: translateY(0);
      margin-bottom: 0;
      border-radius: 0;
    }
    background-color: $white;
    transform: translateY(-$treatment-offer-offset + 15px);
    margin-bottom: -$treatment-offer-offset + 15px;
    padding-bottom: 10px;
  }

  &__body {
    @include mq-s-max() {
      left: 70px;
    }

    position: relative;
    display: block;
    height: 100vh;
    width: auto;
    float: left;
    // 31vh due to the image aspect ratio. 45% is the right margin of the box
    left: calc(45% - 31vh - 5%);
  }

  &__content {
    @include mq-s-max {
      @include fullwidth-breaking-grid;
      padding-bottom: $padding-reduced;
    }
    @include section-absolute-content;

    padding-top: 10px !important;
  }

  &-body__image {
    max-width: 100%;
    height: 100%;
    width: auto;
  }

  &-wrapper {
    @include mq-m-max {
      @include fullwidth-breaking-grid;
      padding-top: $padding-base-section;
      position: relative;
    }

    background-color: $light-blue;
    display: block;
  }

  &__dynamic-text {
    @include mq-s-max() {
      padding: $padding-reduced $padding-reduced-half 0 $padding-reduced-half;
      height: auto;
    }

    display: block;
    overflow: hidden;

    h3 {
      margin: 0;
    }
  }
}

.treatment-offer__articles {
  @include mq-s-max() {
    padding: $padding-reduced-double $padding-reduced $padding-reduced $padding-reduced;
    float: none;
    background: $light-blue;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: none;
    z-index: 1000;
  }

  &-mobile-active {
    @include mq-s-max() {
      display: block;
    }
  }

  display: block;
  float: right;
  width: 55%;
  color: $title-grey;
  position: relative;
  z-index: 2;

  &-mobile-close {
    @include mq-s-max() {
      display: block;
    }

    z-index: 10;
    position: absolute;
    right: $padding-reduced;
    top: $padding-reduced;
    width: $padding-base;
    display: none;
  }
}

.treatment-offer__article {
  @include mq-s-max() {
    padding: $padding-reduced;
  }
  border: 1px solid $border-grey;
  border-bottom: 0;
  padding: $padding-base;
  height: $padding-base * 4;
  background-color: $white;
  display: none;

  &-content {
    display: none;
  }

  &-first {
    border-top-left-radius: $half-border-radius;
    border-top-right-radius: $half-border-radius;
  }

  &-last {
    border-bottom: 1px solid $border-grey;
    border-bottom-left-radius: $half-border-radius;
    border-bottom-right-radius: $half-border-radius;
  }

  &.active,
  &:hover {
    background-color: $dove-blue;
  }

  &-arrow {
    @include arrow-link(blue, 0);
    align-self: center;
    flex: 0 0 20px;
  }

  &-infos {
    @include font-size(16);
    color: $text-grey;
    flex: 1 1 0;

    [class^="icon-"] {
      margin-right: calc($padding-base / 4);
    }

    &-phone {
      @include mq-s-max() {
        display: block;
      }

      vertical-align: middle;

      + .treatment-offer__article-infos-email {
        @include mq-s-max {
          padding-left: 0;
        }
        padding-left: $padding-reduced-double;
      }
    }

    &-email {
      @include mq-s-max() {
        display: block;
        padding-left: 0;
        padding-top: 5px;
      }

      color: $text-grey;
      vertical-align: middle;
    }

    .email-icon,
    .phone-icon {
      @include i-icon-font-closer;

      line-height: 1;

      &:before {
        line-height: 1;
      }
    }

    &-title {
      color: $title-grey;
      margin: 0;
      padding: 0;

      h5 {
        @include font-size(18);
        font-weight: 500;
        line-height: $padding-base * 2;
      }

      &--padding {
        @include mq-s-max {
          padding-bottom: 0;
        }
        padding-bottom: $padding-base-half;

        h5 {
          line-height: 1;
        }
      }
    }
  }
}

.treatment-line {
  @include mq-s-max() {
    border: 0;
  }
  display: none;
  position: absolute;
  border-top: 1px solid $limmi-blue;
  width: 400px;
  margin-top: 2px;
  margin-left: 20px;
  z-index: 1;
}

.dot {
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 30px;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  background-color: $white;
  box-shadow:
    0 0 0 6px $limmi-blue,
    0 0 0 16px rgba($limmi-blue, 0.4);

  &:after {
    content: "";
    position: absolute;
    width: 38px;
    height: 38px;
    background-color: transparent;
    z-index: 9;
    left: 3px;
    top: 3px;
    transform: translate(-50%, -50%);
    border-radius: 200px;
    border: 2px solid $limmi-blue;
  }

  &:hover,
  &.active {
    box-shadow:
      0 0 0 6px $limmi-blue,
      0 0 0 16px rgba($limmi-blue, 1);
  }
}

.gender:nth-child(2) {
  display: none;
}

.presentation-wrapper {
  background-color: $light-blue;
}

.select-gender {
  @include mq-m-max {
    position: absolute;
    top: $padding-base-section;
    left: $padding-reduced;
  }

  @include mq-s-max {
    position: absolute;
    top: $treatment-offer-offset * 2.5;
  }

  width: $treatment-offer-gender-size;
  height: $treatment-offer-gender-size;
  display: block;
  border-radius: $treatment-offer-gender-size;
  background-color: $light-blue;
  position: relative;
  top: $treatment-offer-offset * 2;
  left: 5%;
  text-align: center;
  color: $limmi-blue;
  padding: calc($treatment-offer-gender-size / 4) 0;
  font-size: calc($treatment-offer-gender-size / 3);
  border: 1px solid $limmi-blue;

  &:nth-child(1) {
    @extend .icon-symbol-male;
  }

  &:nth-child(2) {
    @extend .icon-symbol-female;

    @include mq-m-max {
      top: $padding-base-section + $treatment-offer-gender-size + $padding-reduced-half;
    }

    @include mq-s-max {
      top: $treatment-offer-offset * 2.5 + $treatment-offer-gender-size + $padding-reduced-half;
    }

    top: calc($treatment-offer-offset * 2) + calc($treatment-offer-gender-size / 2);
    padding-left: 2px;
  }

  &:hover,
  &.active {
    border-color: $white;
    background-color: $white;
    box-shadow: $shadow-s;
  }
}

.bodies:after {
  content: "";
  display: block;
  clear: both;
}

.inline-icons {
  text-align: center;

  i {
    &.phone-icon,
    &.email-icon,
    &.calendar-icon,
    &.location-pin-icon,
    &.clock-icon,
    &.fax-icon {
      display: inline-block;
      left: 0;
      margin: 0 $padding-reduced;
    }
  }
}
