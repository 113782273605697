// This whole SASS needs to be refactor in the future.
// Also the associated template is not perfect and needs to be rewritten
// in the future, if we will have time.
// TODO Carlo

@mixin floating-teaser($hoffset-desktop, $voffset-mobile) {
  @include mq-m-max {
    max-width: 330px;
  }

  @include mq-s-max {
    padding: $padding-reduced;
    max-width: 100%;
    width: calc(100% - #{$padding-reduced-double});
    left: $padding-reduced;
    min-height: 120px;
    transform: translate(0, $voffset-mobile);

    &:hover {
      transform: translate(0, $voffset-mobile) !important;
    }
  }

  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 368px;
  width: 100%;
  height: auto;
  margin: 0;
  padding: $padding-base;
  transform: translate($hoffset-desktop, -50%);

  &:hover {
    cursor: default;
    transform: translate($hoffset-desktop, -50%);
  }

  .email-icon,
  .phone-icon,
  .calendar-icon,
  .location-pin-icon,
  .clock-icon,
  .share-alt-icon,
  .fax-icon,
  .globe-icon {
    &:hover {
      color: $text-grey;
      cursor: text;
    }

    a {
      color: $text-grey;
      cursor: pointer;
    }
  }
}

.wysiwyg {
  .email-icon,
  .phone-icon,
  .calendar-icon,
  .location-pin-icon,
  .clock-icon,
  .share-alt-icon,
  .fax-icon,
  .globe-icon {
    @include i-icon-font-closer;
  }
}

.widget-text {
  // @include last-p-no-margin;

  &--two-third + &--grey-box {
    @include mq-s-max {
      margin-top: $padding-reduced;
    }
  }

  &--grey-box {
    // @extend %widget-text-heading-left;

    .widget-text__inner {
      @include mq-s-max {
        padding: $padding-reduced;
      }
      // @include last-p-no-margin;

      background: $dove-blue;
      border-radius: $border-radius;
      padding: $padding-base;
    }
  }

  &__teaser {
    @include teaser-hover;
    padding: $padding-base;
    border-radius: $border-radius;
    box-shadow: $shadow-s;
    overflow: hidden;
    // height: 306px; // TODO all - Temporary height
    background: $white;
    transition: linear 0.3s;
    margin-bottom: $padding-reduced-double;

    header + .wysiwyg {
      margin-top: 20px;
    }

    &-left {
      // I have to use directly '368px' here because I can't use calc() inside a transform() due to a IE10/11 bug
      // 368px is the width of a m-4 column.
      $hoffset-desktop: -368px - $padding-base-half;
      $hoffset-tablet: $hoffset-desktop + ($padding-incremented - $padding-base-half);
      $voffset-mobile: calc(-100% - #{$padding-reduced-half});
      @include floating-teaser($hoffset-desktop, $voffset-mobile);

      @include mq-m-max {
        transform: translate($hoffset-tablet, -50%);
      }

      @include mq-s-max {
        transform: translate(0, $voffset-mobile);

        &:hover {
          transform: translate(0, $voffset-mobile) !important;
        }
      }
    }

    &-right {
      $hoffset-desktop: $padding-base-half;
      $voffset-mobile: $padding-reduced-half;
      @include floating-teaser($hoffset-desktop, $voffset-mobile);

      @include mq-s-max {
        &:hover {
          transform: translate(0, $voffset-mobile) !important;
          // transform: none !important;
        }
      }
    }

    &-left,
    &-right {
      // Adding specificity crap because of poor initial SASS analysis and implementation. Carlo
      .teaser__title {
        @include mq-s-max() {
          @include font-size(20);
          line-height: 1.2;
        }

        @include font-size(32);
        line-height: 1.2;
        font-family: $primary-font;
        font-weight: $light;
      }
    }

    .calendar-icon,
    .clock-icon {
      &:before {
        line-height: 1em;
      }
    }

    .location-pin-icon {
      &:before {
        line-height: 1.4em;
      }
    }

    .teaser__text .sa-link {
      margin-top: $padding-reduced;
    }
  }

  p {
    img {
      width: auto;
      margin: $padding-reduced-double $padding-reduced;
    }
  }

  .btn--primary {
    @include mq-s-max() {
      width: 100%;
    }
  }
}

.widget-text-accordion {
  @include mq-s-max {
    width: 100%;
  }

  width: calc(100% / 3);
  padding-right: $padding-base;

  p {
    line-height: 1;
  }

  .sa-link {
    margin-top: $padding-base-half;
  }
}

@include mq-s-max() {
  .widget-text__intro-menu {
    position: relative;
    bottom: auto;
    box-shadow: $shadow-s;
    margin-top: $padding-reduced;

    ul li {
      width: 100%;
    }
  }
}

// form elements have only a top margin, intermixed text elements must behave the same
.form-base .widget-text {
  @include mq-s-max {
    width: 100%;
  }

  margin-top: $padding-base-section;
  padding: 0;
  width: $form-field-size;
}

.widget-column {
  .widget-text--grey-box {
    @include mq-s-max {
      margin-top: $padding-reduced;
    }

    width: 100% !important;

    .widget-text__inner {
      @include mq-m-max {
        padding: $padding-base-half;
      }
    }
  }
}
