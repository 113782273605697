////////////////////////////////////////////////////////////////////////////////
// Buttons
////////////////////////////////////////////////////////////////////////////////

.btn {
  @extend .button__text;

  @include mq-s-max() {
    width: 100%;
  }

  margin: 0 auto;
  transition: all 0.15s ease-in-out;
  border-radius: 5px;
  // sass-lint:disable no-vendor-prefixes
  -webkit-appearance: none;
  // sass-lint:enable no-vendor-prefixes
  border: 0;
  cursor: pointer;
  display: inline-block;
  padding: $padding-reduced - 7 $padding-reduced + $padding-reduced-half;
  line-height: 21.5px;
  text-decoration: none;
  text-align: center;
  background-color: transparent;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: 0;
  }

  &--primary {
    @include mq-s-max() {
      width: 100%;
    }

    background: $limmi-blue;
    color: $white;
    transition: all 0.3s ease-in-out;

    &:hover {
      opacity: 0.5;
      transition: all 0.3s ease-in-out;
    }

    &:focus {
      opacity: 1;
    }

    &:visited {
      color: $white;
    }

    &:after {
      @include mq-s-max() {
        display: inline-block;
        float: none;
        top: 2px;
        position: relative;
      }

      float: right;
      height: 0.9em;
      margin-top: 0.21em;
      width: 1.2em;
      margin-left: $padding-reduced-half;
      background: url("../img/icons/arrow_white.svg") no-repeat;
      background-position: bottom;
      content: "";
      pointer-events: none;
    }

    a {
      color: $white;

      &:visited {
        color: $white;
      }
    }
  }

  &--secondary {
    border: 1px solid $text-grey;
    transition: all 0.3s ease-in-out;
    color: $text-grey;

    &:hover {
      opacity: 0.5;
      transition: all 0.3s ease-in-out;
    }

    &:focus {
      opacity: 1;
    }
  }

  &--margin-top {
    margin-top: $padding-reduced-double;
  }
}

.button__no-text {
  margin: 0 auto;
  width: $padding-reduced-triple;
  height: $padding-reduced-triple;
  border: 1px solid $limmi-blue;
  border-radius: 100%;
  background: url("../img/icons/arrow_blue.svg") no-repeat;
  background-position: center;
  background-size: $padding-reduced;
}

.widget-image-text + .button__no-text,
.widget-text + .button__no-text {
  margin-top: $padding-base;
}
