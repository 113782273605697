.post-item-featured-plugin__text__share--list {
  position: relative;
}

.share-tooltip {
  background-color: $light-grey;
  border: $border $border-grey;
  border-radius: $border-radius;
  bottom: $padding-reduced-double;
  box-shadow: $shadow-l;
  display: none;
  padding: $gutter-half;
  position: absolute;
  z-index: 1;

  &.active {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: $share-box-width;
  }

  p {
    &.share-tooltip__title {
      @include font-size(18);
      color: $black;
      flex: 1 0 100%;
      margin: 0;
    }
  }

  a {
    &.share-tooltip__item {
      display: flex;
      margin-bottom: 0;
      margin-top: $padding-reduced;
    }
  }

  .copy-url-success {
    color: $black;
    display: none;
    margin-left: $gutter-half;

    &.active {
      display: flex;
    }
  }
}

.clipboard {
  display: none;
}
