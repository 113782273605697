.poll {
  &__steps {
    padding-top: $padding-base;
    padding-bottom: $padding-base;
  }

  &__actions {
    text-align: right;
  }

  &__stats .form-field {
    width: 100%;
  }

  &--result {
    &__answer-counter {
      margin: 0;
    }

    &__answer-text {
      @include font-size(26);
      margin-bottom: $gutter-half;
    }

    // TODO Please note the use of BEM mix here! Carlo
    // https://en.bem.info/forum/4/
    &__rating {
      margin-bottom: $gutter-quadruple;
    }
  }

  // TODO Carlo this is repeated from persons.scss, consider using a mixin
  &__back-link {
    color: $limmi-blue;

    &:visited {
      color: $limmi-blue;
    }

    &:before {
      @include arrow-link($color: blue, $margin: 0 $gutter-half 0 0);
      transform: rotate(180deg);
    }
  }

  &__actions--btn + &__actions--btn {
    margin-left: $padding-base;
  }
}

// TODO Carlo this is a bad practice. The most elegant way to do this is to
// customize the form template!
.poll__step {
  label {
    display: block;
    margin-top: $padding-base;

    &:first-child {
      margin-top: 0;
    }

    &.rating__label {
      display: inline-block;
      margin-top: 0;
    }
  }

  [for="id_date_of_stay_month"] {
    width: 100%;
  }

  .choices {
    max-width: 200px;
    width: 100%;
    display: inline-block;

    + .choices {
      margin-left: $padding-base;
    }
  }
}

$smile-size: 28px;

@mixin bg-offset() {
  @for $i from 1 through 5 {
    &:nth-child(#{$i * 2}) {
      background-position-x: $i * $smile-size;
    }
  }
}

.rating {
  &__input {
    display: none;

    &:checked + label {
      opacity: 1;
    }
  }

  &__label {
    @include bg-offset();
    cursor: pointer;
    position: relative;
    display: inline-block;
    width: $smile-size;
    height: $smile-size;
    background-image: url("../img/icons/smileys.png");
    opacity: 0.3;
    margin-right: $gutter-half;

    &:hover {
      opacity: 1;
    }
  }
}
