.karriere {
  &__intro {
    margin: $padding-reduced-quadruple auto $padding-reduced-double auto;
    max-width: 950px;
    text-align: center;
  }

  &-placeholder-content {
    margin-bottom: $padding-reduced-double;
  }

  h2 {
    @include mq-s-max() {
      margin-bottom: $padding-reduced;
    }

    text-align: center;
    margin-bottom: $padding-reduced-double;
  }

  + .widget-section--white {
    padding-top: $padding-reduced-double;
  }
}

.karriere-filters {
  @include mq-s-max() {
    padding: $padding-reduced;
    margin-left: -$padding-reduced;
    margin-bottom: $padding-incremented-half;
    border-radius: 0;
    position: relative;
    width: calc(100% + #{$padding-reduced-double});
  }

  background: $light-blue;
  border-radius: $border-radius;
  padding: $padding-reduced-triple calc($padding-reduced * 6);
  margin-bottom: $padding-reduced-quadruple;

  p {
    margin: 0;
  }

  form {
    border-top: 0;
    padding: 0;

    .form-field:first-of-type {
      margin-top: 0;
    }
  }

  &__options {
    @include mq-s-max() {
      margin-left: 0;
      margin-right: 0;
    }

    display: flex;
    margin-left: -$padding-reduced-half;
    margin-right: -$padding-reduced-half;
    flex-wrap: wrap;

    .choices {
      @include mq-s-max() {
        flex: 1 1 100%;
        width: 100%;
        margin: 0 0 $padding-reduced-half 0;
      }

      flex: 1 1 auto;
      margin: 0 $padding-reduced-half;
    }

    [type="text"],
    [type="search"] {
      @include mq-s-max() {
        flex: 1 1 100%;
        width: 100%;
        margin: 0 0 $padding-reduced-half 0;
      }

      flex-grow: 1;
      margin: 0 $padding-reduced-half $padding-reduced;
    }
  }

  &__actions {
    @include mq-s-max() {
      margin-top: 0;
      margin-bottom: 0;
    }

    text-align: center;
    margin-top: $padding-reduced-double;

    .btn {
      @include mq-s-max() {
        margin: 0;
        width: 100%;

        + .btn {
          margin-top: $padding-reduced-half;
        }
      }

      margin: 0 $padding-reduced-half;
    }

    [type="submit"] {
      position: relative;
      padding-right: $padding-reduced-triple;

      &:after {
        @include arrow-link($color: blue, $margin: 0 $padding-reduced-half 0 0);
        position: absolute;
        right: $padding-reduced-half;
        top: 50%;
        transform: translateY(-50%);
        background: url("../img/icons/pfeil_weiss.png") no-repeat;
        pointer-events: none;
      }
    }
  }

  &--plugin {
    margin-bottom: 0;
    padding: 30px $padding-reduced;
    background-color: transparent;

    [type="text"] {
      margin: 0;
    }
  }
}

.karriere-offers {
  @include within-grid;
  @include mq-s-max() {
    margin-bottom: $padding-reduced-double;
  }

  margin-bottom: $padding-reduced-quadruple;
  border-left: 1px solid $border-grey;
  border-radius: $border-radius;

  &--plugin {
    margin-bottom: $padding-base;
    margin-top: $padding-base;
  }
}

.karriere-offer {
  @include rounded-container();
  @include rounded-container-2-col();

  &--keine {
    .karriere-offer__link {
      color: $title-grey;
      padding: 30px;
      text-align: center;
      min-height: auto;

      &:hover {
        background-color: transparent;
      }
    }
  }
}

.job-detail {
  &__narrow-container {
    @include mq-s-max() {
      padding: 0;
    }

    padding: 0 $padding-percentage-base;

    .widget-text {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__header {
    text-align: center;
    margin-bottom: $padding-reduced-double;

    &-meta {
      @include mq-s-max() {
        margin-top: 0;
      }

      width: 100%;
      margin: $padding-reduced-double 0 $padding-reduced 0;
      padding: 0;

      li {
        @include font-size(15);
        display: inline-block;
        margin-right: $padding-reduced-half;
        color: $limmi-blue;
        font-weight: $medium;
        text-transform: uppercase;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  &__content-box {
    width: 100%;
    position: relative;

    td {
      vertical-align: initial;
    }

    h5 {
      margin: 0;
    }

    .icon-container + .icon-container {
      margin-left: $padding-reduced;
    }
  }

  &__share {
    @include mq-s-max() {
      margin-bottom: $padding-reduced;
    }

    margin: $padding-reduced-double 0 $padding-reduced-quadruple 0;
    position: relative;

    a {
      display: block;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .share-tooltip {
      bottom: $padding-incremented-double;
    }

    .share-link {
      @include mq-s-max() {
        margin-bottom: $padding-reduced;
      }
      color: $text-grey;
      cursor: pointer;
      list-style: none;
      margin-bottom: $padding-reduced-double;
    }

    .share-icon {
      &:before {
        @extend .icon-share-alt;
        @include i-icon-font();
        color: $text-grey;
        margin-right: $padding-reduced-half;
      }
    }

    .back-link {
      &:before {
        @include arrow-link($color: blue, $margin: 0 $padding-reduced-half 0 0, $flip: true);
      }
    }
  }

  .widget-section--white {
    padding-top: 0;
    padding-bottom: 0;
  }

  .download-pdf {
    @include mq-m-max() {
      display: block;
      position: relative;
      text-align: right;
      width: 100%;
    }

    color: $limmi-blue;
    position: absolute;
    top: 0;
    right: 0;
  }

  .pdf-icon {
    @extend .icon-doc;
    @include i-icon-font();
    color: $limmi-blue;
  }

  .widget-text__header h3 {
    @include mq-s-max() {
      margin-top: $padding-reduced;
    }

    margin: $padding-reduced-double 0 $padding-reduced 0;
  }

  .sa-link-back a:before {
    @include arrow-link($color: blue, $margin: 0 $padding-reduced-half 0 0, $flip: true);
    vertical-align: middle;
  }
}
