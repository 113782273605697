// Babygalerie list content

.babygalerie-content {
  text-align: center;
}

// Babygalerie slider plugin

.babygalerie-plugin {
  @include mq-s-max() {
    padding-top: $padding-reduced-double;
  }

  .baby-image-container {
    @include mq-s-max() {
      padding-bottom: 50px;
    }

    text-align: center;

    img {
      @include mq-s-max() {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      border-radius: $border-radius;
    }
  }

  .slick-arrow {
    @include mq-s-max() {
      top: 35%;
    }

    display: block !important;
    background: none;

    &:before {
      @include font-size(20);
      font-weight: $light;
      color: $text-grey;
    }
  }

  .baby-info {
    @include mq-s-max() {
      background-color: $dove-blue;
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }

    width: 100%;
    position: absolute;
    bottom: 3%;
    text-align: center;

    &__name,
    &__birth {
      display: block;
      color: $text-grey;
    }
  }

  &__content {
    @include mq-s-max() {
      padding: 0;
    }

    padding: 0 $padding-reduced;
  }

  &__title {
    @include teaser-title;
  }
}

.person-baby__actions .icon-share-alt:before {
  line-height: 1.2em;
  vertical-align: top;
}
