$inner-ratio: (100% + $padding-percentage-base * 2);
$menu-2-width: calc($inner-ratio / 3);
$menu-3-width: $padding-percentage-base * 2 + 33%;
$submenu-2-height: 70vh;
$submenu-3-height: calc(#{$submenu-2-height} - 120px);

.cms.cms-reset + .site-header {
  top: 46px !important;
}

.cms.cms-reset ~ .site-header-mobile {
  top: 46px !important;

  .site-nav-mobile {
    top: 46px + $header-mobile-h !important;
  }
}

.site-header {
  position: fixed;
  width: 100%;
  z-index: 99;
  top: 0;

  a {
    display: inline-block;
    vertical-align: bottom;
    color: $title-grey;
    font-family: $primary-font;

    &:hover {
      color: $limmi-blue;
      opacity: 1;
    }
  }

  &__top {
    background: $dove-blue;
  }

  &__bottom {
    background: $white;
  }

  &:after {
    position: absolute;
    top: 50%;
    bottom: 0;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    border-radius: 60px / 10px;
    // This also affects the breadcrumb top padding
    box-shadow: 0 10px 20px 0 rgba($black, 0.1);
    content: "";
    z-index: -1;
  }
}

.top-nav {
  &__item {
    @include mq-s-max() {
      @include font-size(16);
      letter-spacing: 0.36px;
      line-height: 20px;
    }

    @include font-size(14);
    color: $title-grey;
    font-family: $primary-font;
    font-weight: $regular;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    padding: 12px $padding-reduced;

    &.current {
      background-color: $white;
    }
  }

  &__mobile-item {
    display: none;
  }
}

.site-nav,
.site-nav__menu {
  text-align: right;
  position: relative;

  &__item {
    @extend h6;

    a {
      width: 100%;
    }
  }

  > .site-nav__item {
    @include mq-m-l() {
      margin: $padding-reduced-half 0 0;
      padding-top: 0;
    }

    margin: $padding-reduced 0 0;
    padding: $padding-reduced $padding-reduced-half;
    display: inline-block;
    width: auto;
    font-weight: $regular;

    &.current a {
      color: $limmi-blue;
    }

    a {
      color: $title-grey;

      &:hover {
        color: $limmi-blue;
      }
    }
  }

  &__logo {
    @include mq-m-l() {
      float: none;
      height: auto;
      padding-bottom: 0;
    }

    width: 210px;
    height: 40px + $gutter-double; // Needed by IE11
    float: left;
    padding: $gutter 0;
    line-height: 1;
    position: relative;
    z-index: 1;

    &-container {
      @include mq-m-l() {
        display: inline-block;
        text-align: center;
        width: 100%;
      }
    }
  }
}

.site-nav-submenu-level-2 {
  @include mq-m-l() {
    top: $padding-incremented;
  }

  position: absolute;
  left: 0;
  top: $padding-incremented * 2;
  width: 100%;
  padding: $padding-reduced-double + 10px $padding-percentage-base;
  border-radius: 0 0 $border-radius $border-radius;
  background: $white;
  box-shadow: $shadow-l;
  text-align: left;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s;
  z-index: 0;
  height: $submenu-2-height;
  max-height: $submenu-2-height;

  &.active {
    pointer-events: auto;
    opacity: 1;
  }

  .site-nav-submenu-container {
    overflow-y: auto;
    // sass-lint:enable no-vendor-prefixes
    max-height: calc(100% - #{$padding-base-half * 2 + $padding-base + 18px});
    padding-right: $padding-incremented;
    width: calc(#{$menu-2-width} + #{$padding-incremented});
  }

  .site-nav__item {
    @include font-size(16);
    padding: 0;
    display: block;
    margin: 0;
    border-bottom: 1px solid $light-grey;
    position: relative;
    font-weight: $regular;

    a {
      padding: 14px 0;
    }

    &.children:after {
      @include arrow-link($color: blue, $margin: 0 $padding-reduced-half 0 0);
      position: absolute;
      right: -10px;
      top: 50%;
      transform: translateY(-50%);
    }

    // The first item in the menu is different in style

    &--first {
      @include font-size(18);
      font-weight: $medium;
      border-bottom: 0;
      margin-bottom: $padding-reduced;

      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 70px;
        height: 1px;
        background-color: $limmi-blue;
      }
    }
  }
}

.site-nav-submenu-level-3 {
  position: absolute;
  top: 70px;
  left: $menu-3-width;
  max-width: 50%;
  width: 100%;
  display: none;
  height: $submenu-3-height;

  &.active {
    display: block;
    // sass-lint:disable no-vendor-prefixes
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      overflow-y: auto !important;
    }
    // sass-lint:enable no-vendor-prefixes
  }

  .site-nav-submenu-container {
    // sass-lint:disable no-vendor-prefixes
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      overflow-y: hidden;
    }
    width: calc(100% - #{$padding-incremented});
  }

  > .site-nav__item {
    @include font-size(16);
    display: block;
    padding: 0;
    margin: 0;
    font-weight: $regular;

    a {
      padding: $padding-reduced-half 0;
    }

    &--first {
      &:before {
        display: none;
      }

      a {
        font-weight: bold;
        color: $text-grey;
        position: relative;
        padding: $padding-reduced 0;
        margin-bottom: $padding-reduced;

        &:before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 70px;
          height: 1px;
          background-color: $limmi-blue;
        }
      }
    }
  }
}

.site-nav-submenu-overlay {
  @include mq-s-max() {
    display: none;
    visibility: hidden;
  }

  position: fixed;
  background: rgba($black, 0.6);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -2;
}

// Smartphone styles

@mixin mobile-submenu($bg: $white) {
  height: calc(100% - #{$header-mobile-h});
  background: $bg;
  width: 100%;
}

@mixin mobile-menu-item($transform: none, $bg: $white) {
  display: block;
  height: $header-mobile-h;
  line-height: $header-mobile-h;
  width: 100%;
  color: $text-grey;
  text-transform: $transform;
  vertical-align: top;
  text-align: left;
  background-color: $bg;

  &:visited {
    color: inherit;
  }
}

.site-header-mobile {
  @include mobile-menu-item();
  display: none;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: $header-mobile-h;
    box-shadow: 0 0 8px 0 rgba($black, 0.2);
    pointer-events: none;
  }

  &__logo {
    display: block;
    position: relative;
    left: $padding-reduced;
    top: 15px;
    width: 160px;
    height: 34px; // Needed by IE11
    padding: 0;
    line-height: 1;
  }

  &__nav-btn {
    @include font-size(28);
    position: absolute;
    top: 0;
    right: 0;
    padding: 14px $padding-reduced;
    background: transparent;
  }
}

.site-nav-mobile {
  @include mobile-submenu($dove-blue);
  position: fixed;
  top: $header-mobile-h;
  left: 0;
  right: 0;
  pointer-events: none;
  transform: translateY(-100%);
  opacity: 0;
  transition:
    transform 0.2s,
    opacity 0.4s;

  &.active {
    pointer-events: auto;
    transform: translateY(0);
    opacity: 1;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__current {
    @include mobile-menu-item(uppercase, $dove-blue);
    padding: 0 $padding-reduced;

    i {
      float: right;
    }

    &.hidden {
      display: none;
      visibility: hidden;
    }
  }

  &__sections,
  &__submenu {
    position: absolute;
    top: 0;
    width: 100%;
    transform: translateX(100%);
    transition: transform 0.1s;
    z-index: 9;

    &.active {
      transform: translateX(0);
      height: 100%;
    }

    .hidden {
      display: none;
    }
  }

  &__sections.active {
    background-color: $dove-blue;
  }

  &__submenu {
    @include mobile-submenu();
    top: -$header-mobile-h;

    &.site-nav-mobile__submenu-level-3 {
      top: 0;
    }
  }

  &__section-item {
    @include mobile-menu-item(uppercase, $dove-blue);
    padding: 0 $padding-reduced;

    &--back {
      background: $white;
      text-transform: none;

      i {
        margin-right: $padding-reduced;
      }
    }
  }

  &__pages {
    @include mobile-submenu();
    position: absolute;
    top: $header-mobile-h;
    z-index: 7;

    .hidden {
      display: none;
    }
  }

  &__page-item {
    @include mobile-menu-item();
    padding: 0 $padding-reduced;
    position: relative;

    &:before {
      position: absolute;
      height: 1px;
      left: $padding-reduced;
      right: $padding-reduced;
      bottom: 0;
      background: $light-grey;
      content: "";
    }

    &.children:after {
      @include arrow-link($color: blue, $margin: 0 $padding-reduced-half 0 0);
      position: absolute;
      right: $padding-reduced;
      top: 50%;
      transform: translateY(-50%);
    }

    &--back {
      background: $dove-blue;

      &:before {
        display: none;
      }
    }

    &--current {
      font-weight: $bold;

      &:before {
        background: $limmi-blue;
        right: 80%;
      }
    }

    &:visited {
      color: inherit;
    }
  }
}

@include mq-s-max() {
  .site-header {
    display: none;
    visibility: hidden;
  }

  .site-header-mobile {
    display: block;
    visibility: visible;
  }

  .page-container,
  .page-container--brustzentrum,
  .page-container--adipositaszentrum {
    padding-top: $header-mobile-h;
  }
}

// Custom media queries to make the header a bit fluid

.top-nav__item {
  @include mq-l-max {
    @include font-size(13);
    padding-left: $padding-reduced-half;
    padding-right: $padding-reduced-half;
  }

  @media only screen and (max-width: 900px) {
    @include font-size(12);
  }
}

.site-nav,
.site-nav__menu {
  > .site-nav__item {
    @include mq-m-l() {
      @include font-size(17);
      padding-left: $padding-reduced-quarter;
      padding-right: $padding-reduced-quarter;
    }

    @include mq-s-m() {
      @include font-size(15);
      padding-left: calc($padding-reduced-quarter / 2);
      padding-right: calc($padding-reduced-quarter / 2);
    }
  }
}

.site-nav__menu {
  @include mq-m-l() {
    text-align: center;
  }
}
