@mixin rounded-container() {
  position: relative;
  border-right: 1px solid $border-grey;
  border-bottom: 1px solid $border-grey;
  overflow: hidden;

  &:after {
    @include mq-s-max() {
      right: 0;
    }

    @include arrow-link($color: blue, $margin: 0 $padding-reduced-half 0 0);
    position: absolute;
    right: $padding-reduced;
    top: 50%;
    transform: translateY(-50%);
  }

  &__link {
    @include mq-s-max() {
      padding: $padding-base-half $padding-base $padding-base-half $padding-base-half;
      min-height: 80px;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: $padding-base $padding-reduced-triple $padding-base $padding-base;
    min-height: 130px;

    &:hover {
      background-color: $dove-blue;
    }
  }

  &__title {
    color: $title-grey;
  }

  &__category {
    @include font-size(14);
    display: block;
    color: $limmi-blue;
    text-transform: uppercase;
  }
}

@mixin rounded-container-1-col() {
  border-left: 1px solid $border-grey;
  border-right: 1px solid $border-grey;

  &:first-child {
    border-top-left-radius: $half-border-radius;
    border-top-right-radius: $half-border-radius;
    border-top: 1px solid $border-grey;
  }

  &:nth-last-child(2) {
    border-bottom-right-radius: $half-border-radius;
    border-bottom-left-radius: $half-border-radius;
  }
}

@mixin rounded-container-2-col() {
  &:nth-child(1) {
    @include mq-s-max() {
      border-top-right-radius: $half-border-radius;
    }
    border-top-left-radius: $half-border-radius;
    border-top: 1px solid $border-grey;
  }

  &:nth-child(2) {
    @include mq-s-max() {
      border-top-right-radius: 0;
      border-top: 0;
    }

    border-top-right-radius: $half-border-radius;
    border-top: 1px solid $border-grey;
  }

  &:last-child:nth-child(even) {
    border-bottom-right-radius: $half-border-radius;
  }

  &:last-child:nth-child(odd) {
    border-bottom-right-radius: $half-border-radius;
    border-bottom-left-radius: $half-border-radius;
  }

  &:nth-last-child(2):nth-child(even) {
    @include mq-s-max() {
      border-bottom-right-radius: 0;
    }

    border-bottom-right-radius: $half-border-radius;
  }

  &:nth-last-child(2):nth-child(odd) {
    @include mq-s-max() {
      border-bottom-left-radius: 0;
    }

    border-bottom-left-radius: $half-border-radius;
  }
}
