.tabs {
  &-container {
    width: 100%;
  }

  &-header {
    list-style: none;
    padding: 0;

    &__item {
      &.active a {
        position: relative;
        top: 1px;
        background: white;
      }
    }
  }

  &-list {
    display: block;
    margin-top: 0;
    padding: 0;
    margin-bottom: 14px;

    li {
      cursor: pointer;
      display: none;
      width: 100%;

      a {
        display: block;
        width: 100%;
        padding: 15px 30px;
        border-left: 1px solid $border-grey;
        border-right: 1px solid $border-grey;
        border-top: 1px solid $border-grey;
        color: $title-grey;
        text-decoration: none;
        text-transform: uppercase;
        text-align: left;
      }
    }
  }

  &-content {
    border: 1px solid $border-grey;
    border-bottom: 0;

    &:first-of-type {
      border-top-left-radius: $half-border-radius;
      border-top-right-radius: $half-border-radius;
    }

    &:last-of-type {
      border-bottom: 1px solid $border-grey;
      border-bottom-left-radius: $half-border-radius;
      border-bottom-right-radius: $half-border-radius;
    }
  }
}

.tab-content {
  @include mq-m-max {
    padding: 0 $padding-reduced - 5px $padding-reduced;
  }

  display: none;
  height: 100%;
  padding: 0 50px;

  > *:first-of-type:not(.widget-text-accordion) {
    @include mq-s-max() {
      margin-top: 0;
    }

    margin-top: 25px;
  }

  > div {
    @include mq-s-max() {
      padding: 0;
      margin-top: 0;
      margin-bottom: 25px;
    }

    margin-bottom: $padding-reduced-double;
  }

  hr {
    @include mq-s-max() {
      display: none;
    }
  }

  &.active {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
  }

  .widget-image-text {
    @include mq-s-max() {
      margin-bottom: $padding-reduced + 5px;
    }

    margin-bottom: $padding-reduced-double;
  }

  .widget-image__default {
    @include mq-s-max() {
      margin-bottom: $padding-reduced;
    }

    @include no-gutter;
  }

  .widget-text__teaser {
    @include mq-s-max() {
      padding: $padding-reduced-half 0;
      border-bottom: 1px solid $border-grey;
    }

    margin: 0;
    padding: 0;
    box-shadow: none;
    border-radius: 0;
    height: auto;

    .teaser__text {
      .sa-link {
        margin-top: $padding-reduced;
      }
    }
  }

  .widget-image .widget-image__media,
  .widget-image .media {
    @include mq-s-max() {
      border-radius: $border-radius;
      margin-left: 0;
      width: 100%;
    }
  }

  .widget-text {
    @include mq-s-max() {
      hr {
        display: none;
      }
    }

    @include no-gutter();

    // If the first element of the tab content is a Text plugin, decrease its margin

    &:first-child {
      margin-top: $padding-reduced-half;
    }

    &--grey-box {
      @include mq-s-max() {
        @include no-gutter();
      }

      @include no-gutter(15px, 0);
    }
  }

  .widget-text--two-third {
    @include no-gutter(0, $padding-base-section);
  }

  table {
    @include mq-s-max() {
      width: 100%;

      tr {
        display: block;
        margin-bottom: $padding-reduced-half;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      th {
        height: auto;
        font-weight: bold;
      }

      td {
        display: block;
        margin: 0;
        height: auto;
      }
    }
  }
}

.widget-image-text-tab {
  &__image--left {
    @include mq-m-max {
      margin-right: $padding-reduced;
    }

    @include mq-s-max() {
      clear: both;
      margin-right: 0;
      margin-bottom: $padding-reduced;
    }

    float: left;
    margin-right: 50px;
  }

  &__image--right {
    @include mq-m-max {
      margin-left: $padding-reduced;
    }

    @include mq-s-max() {
      clear: both;
      margin-left: 0;
      margin-top: $padding-reduced;
    }

    float: right;
    margin-left: 50px;
  }

  &__text-content {
    @include mq-m-max {
      width: 45%;
    }

    @include mq-s-max() {
      width: 100%;
    }

    width: 50%;
    display: inline-block;
  }

  &__description table {
    width: 90%;
  }
}

.accordion-tab {
  @include mq-m-max {
    padding: $padding-reduced $padding-reduced-triple $padding-reduced $padding-reduced - 5px;
  }

  @include mq-s-max() {
    padding: $padding-reduced $padding-incremented $padding-reduced $padding-reduced - 5px;
    display: block;
  }

  position: relative;
  display: block;
  padding: $padding-reduced + 2 60px $padding-reduced + 2 50px;
  cursor: pointer;
  margin: 0;
  text-align: left;

  i {
    @include mq-s-max() {
      right: 15px;
      top: $padding-reduced;
    }

    color: $limmi-blue;
    font-size: 25px;
    line-height: 0;
    position: absolute;
    right: $padding-base;
    top: $padding-base - 2px;
    background: white;
    display: inline-block;
    border-radius: 200px;
  }

  &:hover {
    background-color: $dove-blue;

    i {
      background-color: $dove-blue;
    }
  }

  &:first-of-type {
    border-top-left-radius: $half-border-radius;
    border-top-right-radius: $half-border-radius;
  }

  &:last-of-type {
    border-bottom-left-radius: $half-border-radius;
    border-bottom-right-radius: $half-border-radius;
  }
}
