////////////////////////////////////////////////////////////////////////////////
// Colors
////////////////////////////////////////////////////////////////////////////////

$black: #000;
$dove-blue: #f3f6f7;
$light-grey: #f3f3f3;
$border-grey: #d2d2d2;
$text-grey: #6f6f6f;
$title-grey: #444;
$white: #fff;
$light-blue: #e5f4fa;
$limmi-blue: #0090d2;
$red: #bf2a2a;
$green: #67bc9a;

$cookie-blue: #436786;
$cookie-yellow: #ffed44;

////////////////////////////////////////////////////////////////////////////////
// Fonts
////////////////////////////////////////////////////////////////////////////////

$primary-font: "Quicksand", sans-serif;
$secondary-font: "Hind Siliguri", sans-serif;
$base-line: 18;
$scale-factor: 1.4;
$line-height: 1.6;
$line-height-headings: 1.1;
$letter-spacing: 0.4;

//$title-size-0: $base-line / $scale-factor;
//$title-size-1: $base-line;
//$title-size-2: $title-size-1 * $scale-factor;
//$title-size-3: $title-size-2 * $scale-factor;
//$title-size-4: $title-size-3 * $scale-factor;
//$title-size-5: $title-size-4 * $scale-factor;
//$title-size-6: $title-size-5 * $scale-factor;

//$text-size-0: $title-size-1 / $scale-factor;
//$text-size-1: $base-line / $scale-factor;
//$text-size-2: $base-line;
//$text-size-3: $text-size-2 * $scale-factor;
//$text-size-4: $text-size-3 * $scale-factor;
//$text-size-5: $text-size-4 * $scale-factor;
//$text-size-6: $text-size-5 * $scale-factor;
//$text-size-7: $text-size-6 * $scale-factor;
//$small-text-size: $base-line - 2;

$light: 300;
$regular: 400;
$medium: 500;
$demibold: 600;
$bold: 700;

$padding-base: $gutter;
$padding-base-half: calc($padding-base / 2);
$padding-reduced: 20px;
$padding-incremented: 50px;
$padding-increased-section: 50px;
$padding-percentage-base: 8.3%;
$padding-base-section: 35px;
$padding-base-section-content: 40px;
$line-height-correction-offset: -4px;
$padding-higher-section: 90px;
$padding-increased-section: 50px;

$padding-incremented-double: calc($padding-incremented * 2);
$padding-incremented-half: calc($padding-incremented / 2);
$padding-reduced-double: calc($padding-reduced * 2);
$padding-reduced-triple: calc($padding-reduced * 3);
$padding-reduced-quadruple: calc($padding-reduced * 4);
$padding-reduced-quintuple: calc($padding-reduced * 5);
$padding-reduced-half: calc($padding-reduced / 2);
$padding-reduced-quarter: calc($padding-reduced / 4);
$padding-reduced-three-quarter: calc($padding-reduced * 3 / 4);

$header-desktop-h: 145px;
$header-desktop-no-top-menu-h: 101px;
$header-mobile-h: 60px;
$generic-round-button-size: 50px;
$generic-round-button-border: 2px;
$share-box-width: 210px;
//////////// Box shadow ////////////

$shadow-s: 0 0 10px 0 rgba(0, 0, 0, 0.1);
$shadow-l: 0 30px 20px 0 rgba(0, 0, 0, 0.1);

//////////// Border radius ////////////

$border-radius: 10px;
$half-border-radius: 5px;

//////////// Border radius ////////////
$border: 1px solid;
$border-bold: 2px solid;

/////// Global plugin variables ///////

$teaser-small-height: 420px;

////////// Custom breakpoints //////

$screen-s-m: 900px;
$screen-m-l: 1220px;
