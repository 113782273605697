@mixin intro-menu-small-height {
  @media (max-height: 719px) {
    @content;
  }
}

@mixin intro-menu-medium-height {
  @media (min-height: 720px) and (max-height: 799px) {
    @content;
  }
}

.widget-image {
  @include last-p-no-margin;

  &__media,
  .media {
    overflow: hidden;
    border-radius: $border-radius;
  }

  &__default {
    @include mq-s-max() {
      margin: 0;

      .widget-image__media,
      .media {
        @include full-width-mobile-image;
      }

      + header {
        margin-top: $padding-reduced;
        margin-bottom: $padding-reduced-half;
      }
    }

    margin-bottom: $padding-reduced-double;
  }

  &__intro {
    height: calc(100vh - #{$header-desktop-h});
    width: 100%;
    background-size: cover;
    overflow: hidden;

    &-overlay {
      @include mq-s-max() {
        position: relative;
        height: auto;
      }

      position: absolute;
      height: calc(100vh - #{$header-desktop-h});
      width: 100%;
    }

    &-caption {
      @include intro-menu-small-height {
        top: 5%;
        width: 35%;
      }
      @include intro-menu-medium-height {
        top: 7%;
      }

      @include mq-s-max() {
        position: static;
        width: 100%;
      }

      @include last-p-no-margin;
      position: absolute;
      top: 10%;
      width: 42%;

      h4 {
        @include intro-menu-small-height {
          @include font-size(25);
          line-height: 2;
        }
        @include intro-menu-medium-height {
          @include font-size(28);
          line-height: 2;
        }

        @include mq-s-max() {
          @include font-size(20);
          line-height: 1.5;
        }

        font-weight: $light;
        line-height: 62px;
      }

      .text--big {
        @include intro-menu-small-height {
          @include font-size(48);
        }
        @include intro-menu-medium-height {
          @include font-size(64);
        }

        @include mq-s-max() {
          @include font-size(28);
        }
      }

      .text--evidence {
        @include intro-menu-small-height {
          @include font-size(32);
        }
        @include intro-menu-medium-height {
          @include font-size(48);
        }

        @include mq-s-max() {
          @include font-size(28);
        }
      }
    }

    &-menu {
      @media (max-height: 719px) {
        top: 45%;
      }

      @include mq-s-max() {
        position: static;
        margin-top: $padding-reduced;
        box-shadow: $shadow-s;
      }

      position: absolute;
      padding: 0 $padding-reduced;
      border-radius: $border-radius;
      top: 50%;
      background: $white;
      box-shadow: $shadow-l;

      ul {
        li {
          @include mq-s-max() {
            width: 100%;
          }

          width: 350px;
          border-bottom: 1px solid $light-grey;

          &:last-child {
            border: 0;
          }

          h6 {
            @include intro-menu-small-height {
              @include font-size(15);
              padding: $padding-reduced-half 0;
            }
            @include intro-menu-medium-height {
              @include font-size(16);
              padding: calc($padding-reduced-half * 3 / 2) 0;
            }
            margin: 0;
            padding: $padding-reduced 0;
          }

          a {
            display: block;
            color: $text-grey;
            font-family: $primary-font;
            font-weight: normal;

            &::after {
              @include mq-s-max {
                top: 4px;
              }
              float: right;
              height: $padding-reduced-half + 3px;
              width: $padding-reduced;
              background: url("../img/icons/arrow_blue.svg") no-repeat;
              background-size: contain;
              content: "";
              top: 6px;
              position: relative;
              pointer-events: none;
            }

            &:hover {
              color: $limmi-blue;
              opacity: 1;
            }
          }
        }
      }
    }

    &--colored-border,
    &-mobile--colored-border {
      .widget-image__intro-menu {
        border: $border-bold;
      }

      .wysiwyg h3 {
        @include font-size(15);
        @include mq-s-max() {
          padding-bottom: $padding-reduced-half;
        }
        border-radius: 0 0 $border-radius $border-radius;
        color: $white;
        text-align: center;
        padding-top: $padding-reduced-half;
      }
    }
  }

  &__title {
    @include mq-s-max() {
      margin: 0;
    }
  }

  &--absolute {
    @include mq-m-max() {
      margin: 0;
    }

    margin-top: 0;
    overflow: hidden;

    figcaption {
      position: absolute;
      top: 50%;
      left: 20%;
      width: 500px;

      h4 {
        font-weight: $light;
        line-height: 62px;
      }
    }

    .widget-image__media,
    .media {
      img {
        width: 100%;
      }
    }
  }

  &--full-menu {
    margin-top: $padding-base-section-content;
  }

  &--background {
    height: auto; // height is given by js
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    overflow: hidden;
  }

  &__teaser {
    &-small {
      @include mq-s-max() {
        height: $teaser-small-height;
      }

      @include teaser-hover;

      border-radius: $border-radius;
      box-shadow: $shadow-s;
      overflow: hidden;
      margin-bottom: $padding-reduced-double;
      background: $white;
      position: relative;
      height: 450px; // TODO - all - Temporary height
      transition: linear 0.3s;

      .widget-image__media,
      .media {
        height: 200px;
        overflow: hidden;
        border-radius: 0;

        img {
          width: 100%;
        }
      }

      .widget-image-text__header {
        @include mq-l-max {
          margin-top: 0;
        }

        @include mq-m-max() {
          margin-top: $padding-reduced;
        }
        margin-top: $padding-base;

        + .wysiwyg {
          margin-top: $padding-reduced-three-quarter;
        }
      }

      .teaser {
        &__title {
          padding: 0 $padding-reduced;
          // margin: $padding-reduced 0 0;
        }

        &__text {
          // @include last-p-no-margin;

          p {
            padding: 0 $padding-reduced;
            // margin: 0;
          }

          .sa-link-download {
            padding: $padding-reduced;
          }
        }
      }

      .sa-link {
        color: $limmi-blue;
        width: 100%;
        position: absolute;
        bottom: $padding-base;
        margin-top: 30px; // This is just for checking the box-model

        &:before {
          @include arrow-link($color: blue, $margin: 0 $padding-reduced-half 0 0);
        }
      }
    }
  }

  &__description {
    // @include last-p-no-margin;

    @include mq-s-max() {
      .btn--primary {
        width: 100%;
      }
    }
  }

  &__partners {
    .widget-image__media,
    .media {
      text-align: center;
    }
  }

  &__text-container header {
    @include mq-s-max() {
      margin-top: $padding-reduced;
    }
  }
}

.widget-image__intro-mobile .widget-image__intro-overlay > div {
  position: static;
  width: 100%;
}

.widget-image__img {
  &.left {
    float: left;
  }

  &.right {
    float: right;
  }
}

@include mq-s-max() {
  .widget-image__intro-mobile {
    display: block;
    visibility: visible;

    h4 {
      @include font-size(22);
      line-height: $line-height;
      margin: 0;
    }
  }

  .widget-image__intro {
    height: 0;
    padding-bottom: 93.75%; // 16:15 aspect ratio
    margin-bottom: $padding-reduced;

    .widget-image__text-container {
      display: none;
      visibility: hidden;
    }
  }

  .widget-image--absolute {
    figcaption {
      display: none;
      visibility: hidden;
    }
  }
}
