////////////////////////////////////////////////////////////////////////////////
// Media Queries mixins
////////////////////////////////////////////////////////////////////////////////

@mixin mq-xs {
  @media (max-width: #{$screen-xs - 1}) {
    @content;
  }
}

@mixin mq-s {
  @media (min-width: #{$screen-xs}) {
    @content;
  }
}

@mixin mq-m {
  @media (min-width: #{$screen-s}) {
    @content;
  }
}

@mixin mq-l {
  @media (min-width: #{$screen-m}) {
    @content;
  }
}

@mixin mq-xl {
  @media (min-width: #{$screen-l}) {
    @content;
  }
}

@mixin mq-xs-max {
  @media (max-width: #{$screen-xs}) {
    @content;
  }
}

@mixin mq-s-max {
  @media (max-width: #{$screen-s}) {
    @content;
  }
}

@mixin mq-m-max {
  @media (max-width: #{$screen-m}) {
    @content;
  }
}

@mixin mq-l-max {
  @media (max-width: #{$screen-l}) {
    @content;
  }
}

// Add "-limit" wherever is included "mq-xs, mq-s, ..." to change the behavior
// of the grid, and limit the classes to work only inside their media queries

@mixin mq-xs-limit {
  @media (max-width: #{$screen-xs - 1}) {
    @content;
  }
}

@mixin mq-s-limit {
  @media (min-width: #{$screen-xs}) and (max-width: #{$screen-s - 1}) {
    @content;
  }
}

@mixin mq-m-limit {
  @media (min-width: #{$screen-s}) and (max-width: #{$screen-m - 1}) {
    @content;
  }
}

@mixin mq-l-limit {
  @media (min-width: #{$screen-m}) and (max-width: #{$screen-l - 1}) {
    @content;
  }
}

@mixin mq-xl-limit {
  @media (min-width: #{$screen-l}) {
    @content;
  }
}

////////////////////////////////////////////////////////////////////////////////
// Grid
////////////////////////////////////////////////////////////////////////////////

// sass-lint:disable mixins-before-declarations
.grid {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  margin: 0 auto;

  .grid {
    width: 100%;
  }

  @include mq-xs {
    width: $grid-xs;
  }

  @include mq-s {
    width: $grid-s;
  }

  @include mq-m {
    width: $grid-m;
  }

  @include mq-l {
    width: $grid-l;
  }

  @include mq-xl {
    width: $grid-xl;
  }
}
// sass-lint:enable mixins-before-declarations

////////////////////////////////////////////////////////////////////////////////
// Columns
////////////////////////////////////////////////////////////////////////////////

%column {
  box-sizing: border-box;
  display: block;
  padding-left: calc($gutter / 2);
  padding-right: calc($gutter / 2);
}

@mixin columns {
  @for $i from 1 through $xs-grid {
    .xs-#{$i} {
      @extend %column;
    }
  }
  @for $i from 1 through $s-grid {
    .s-#{$i} {
      @extend %column;
    }
  }
  @for $i from 1 through $m-grid {
    .m-#{$i} {
      @extend %column;
    }
  }
  @for $i from 1 through $l-grid {
    .l-#{$i} {
      @extend %column;
    }
  }
  @for $i from 1 through $xl-grid {
    .xl-#{$i} {
      @extend %column;
    }
  }
}

@mixin xs-grid {
  @for $i from 1 through $xs-grid {
    .xs-#{$i} {
      width: percentage(calc(1 / $xs-grid) * $i);
    }
  }
}

@mixin s-grid {
  @for $i from 1 through $s-grid {
    .s-#{$i} {
      width: percentage(calc(1 / $s-grid) * $i);
    }
  }
}

@mixin m-grid {
  @for $i from 1 through $m-grid {
    .m-#{$i} {
      width: percentage(calc(1 / $m-grid) * $i);
    }
  }
}

@mixin l-grid {
  @for $i from 1 through $l-grid {
    .l-#{$i} {
      width: percentage(calc(1 / $l-grid) * $i);
    }
  }
}

@mixin xl-grid {
  @for $i from 1 through $xl-grid {
    .xl-#{$i} {
      width: percentage(calc(1 / $xl-grid) * $i);
    }
  }
}

////////////////////////////////////////////////////////////////////////////////
// Columns offsets
////////////////////////////////////////////////////////////////////////////////

@mixin xs-grid-offset {
  @for $i from 1 through $xs-grid {
    .off-xs-#{$i} {
      margin-left: percentage(calc(1 / $xs-grid) * $i);
    }
  }
}

@mixin s-grid-offset {
  @for $i from 1 through $s-grid {
    .off-s-#{$i} {
      margin-left: percentage(calc(1 / $s-grid) * $i);
    }
  }
}

@mixin m-grid-offset {
  @for $i from 1 through $m-grid {
    .off-m-#{$i} {
      margin-left: percentage(calc(1 / $m-grid) * $i);
    }
  }
}

@mixin l-grid-offset {
  @for $i from 1 through $l-grid {
    .off-l-#{$i} {
      margin-left: percentage(calc(1 / $l-grid) * $i);
    }
  }
}

@mixin xl-grid-offset {
  @for $i from 1 through $xl-grid {
    .off-xl-#{$i} {
      margin-left: percentage(calc(1 / $xl-grid) * $i);
    }
  }
}

////////////////////////////////////////////////////////////////////////////////
// Generated CSS
////////////////////////////////////////////////////////////////////////////////

// Row
// Use this class on a div outside the columns when you want an element to go
// on a new line and his width is not 100%.

.row {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

// Columns
@include columns;

// Extra small grid
@include xs-grid;
@include xs-grid-offset;

// Small grid
@include mq-s {
  @include s-grid;
  @include s-grid-offset;
}

// Medium grid
@include mq-m {
  @include m-grid;
  @include m-grid-offset;
}

// Large grid
@include mq-l {
  @include l-grid;
  @include l-grid-offset;
}

// Extra large grid
@include mq-xl {
  @include xl-grid;
  @include xl-grid-offset;
}
