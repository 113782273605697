.dropdown-text {
  position: relative;
  width: 100%;

  &__title {
    cursor: pointer;
  }

  &__body {
    display: none;
    visibility: hidden;
    position: absolute;
    z-index: 9;
    top: 60px;
    left: 0;
    max-width: 400px;
    width: 100%;
    height: auto;
    background: $white;
    padding: $padding-reduced;
    border-radius: $border-radius;
    box-shadow: $shadow-s;
    overflow: hidden;

    .sa-link {
      position: relative;
      margin: $padding-reduced;
    }
  }
}

.dropdown-text__title.active + .dropdown-text__body {
  display: block;
  visibility: visible;
}
