.pop-up-modal {
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition:
    visibility 0s linear 0.25s,
    opacity 0.25s 0s;
  width: 100%;
  visibility: hidden;
  z-index: 1000;

  &__container {
    @include mq-m-max() {
      width: 50%;
    }

    @include mq-s-max() {
      width: 80%;
    }
    border: 5px solid transparent;
    left: 50%;
    padding: $gutter;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 30%;
  }

  &__logo {
    position: absolute;
    top: -$gutter-half;
  }

  &__close {
    align-items: flex-end;
    background-color: $black;
    border: $border-bold $white;
    border-radius: 50%;
    color: $white;
    cursor: pointer;
    display: flex;
    font-size: 8px;
    font-weight: $bold;
    height: $padding-reduced;
    justify-content: center;
    position: absolute;
    right: -$gutter-half;
    top: -$gutter-half;
    width: $padding-reduced;
  }

  &__title {
    font-weight: $bold;
  }

  &.show-modal {
    opacity: 1;
    transition:
      visibility 0s linear 0s,
      opacity 0.25s 0s,
      transform 0.25s;
    visibility: visible;
  }

  &.pop-up-modal--slider {
    background-color: inherit;

    .pop-up-modal__container {
      @include mq-m-max() {
        left: -25%;
      }

      @include mq-s-max() {
        left: -40%;
      }
      left: -15%;
      transition: left 0.4s linear;
    }

    &.show-modal .pop-up-modal__container {
      @include mq-m-max() {
        left: 25%;
      }

      @include mq-s-max() {
        left: 40%;
      }
      left: 15%;
    }
  }
}
