$header-height-desktop: 147px;
$header-height-tablet: 172px;
$header-height-mobile: 60px;
$eye-catcher-cirle-size-desktop: 200px;
$eye-catcher-cirle-size-mobile: 150px;
$eye-catcher-image-size: 25px;

.eye-catcher {
  @include mq-m-l() {
    right: $gutter-three-quarter;
    top: calc(#{$header-height-tablet} + #{$gutter-three-quarter});
  }
  @include mq-s-max() {
    height: $eye-catcher-cirle-size-mobile;
    right: $gutter-half;
    top: calc(#{$header-height-mobile} + #{$gutter-half});
    width: $eye-catcher-cirle-size-mobile;
  }
  align-items: center;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  height: $eye-catcher-cirle-size-desktop;
  justify-content: center;
  position: fixed;
  right: $gutter;
  top: calc(#{$header-height-desktop} + #{$gutter});
  width: $eye-catcher-cirle-size-desktop;
  z-index: 1;

  &__text {
    font-weight: $demibold;
    margin-bottom: 0;
    margin-top: $padding-reduced;
    padding: 0 $padding-reduced;
    text-align: center;
  }

  &__link {
    display: block;
    margin-top: $gutter-quarter;
  }

  svg {
    width: $eye-catcher-image-size;
  }
}
