.post-list {
  &__header {
    width: 100%;

    h2 {
      @include mq-s-max() {
        @include font-size(24);
        margin: 0 0 $padding-reduced 0;
      }

      @include font-size(60);
      text-align: center;
      margin: $padding-reduced-double 0;
    }
  }

  &__back-link {
    margin-bottom: $padding-reduced-double;

    p {
      margin: $padding-reduced 0 0 0;
    }

    a:before {
      @include arrow-link($color: blue, $margin: 0 $padding-reduced-half 0 0, $flip: true);
      vertical-align: baseline;
    }
  }

  &__categories {
    @include mq-s-max() {
      margin: $padding-reduced 0;
    }

    padding: $padding-reduced;
    margin: $padding-reduced-triple + 10px 0 $padding-reduced-triple 0;
    border-radius: $border-radius;
    box-shadow: $shadow-s;
    overflow: hidden;
    background: $white;
    text-align: center;

    li {
      display: inline-block;
      margin-right: $padding-reduced-quarter;
      color: $title-grey;

      a {
        @include font-size(18);
        color: $text-grey;
      }

      &:last-of-type {
        margin-right: 0;
      }

      &.separator {
        @include mq-s-max() {
          margin: 0 $padding-reduced-half;
        }

        margin: 0 $padding-reduced;
      }

      a:hover {
        color: $limmi-blue;
      }
    }
  }
}

.post-item-homepage-plugin {
  @include mq-s-max() {
    margin-bottom: 0;
    padding: 0;
  }

  padding: $padding-reduced-half;
  margin-bottom: $padding-reduced-double;

  &__blog-lead {
    p:first-of-type {
      margin-top: 0;
    }
  }

  &__media {
    overflow: hidden;
    border-radius: $border-radius;
  }

  &__content {
    @include mq-s-max {
      padding: 0;
    }
    padding: 0 $padding-reduced;
  }

  &__title {
    @include teaser-title;

    a {
      color: $title-grey;
    }
  }

  &--last {
    @include mq-s-max() {
      margin-top: $padding-reduced-double;
    }
  }

  &__blog-link {
    @include mq-s-max() {
      @include font-size(14);
    }
    @include font-size(16);
    font-weight: $medium;
    line-height: 1;
    margin: 0 0 $padding-reduced-half;
    text-transform: uppercase;

    a {
      color: $limmi-blue;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  header {
    // reduced from styleguide to take font height into account
    margin-bottom: calc($padding-base / 3);
  }

  footer .sa-link {
    @include mq-s-max() {
      margin-top: $padding-reduced;
      margin-bottom: 0;
    }
  }
}

.post-item-featured-plugin {
  padding: 0;
  margin-bottom: $padding-reduced-double;

  &__media {
    overflow: hidden;
    border-radius: $border-radius;
  }

  &__body {
    padding: 0 $padding-percentage-base;
  }

  &__text {
    &__share {
      margin-top: $padding-reduced-double;

      &--list {
        display: inline-block;
      }

      li {
        @include font-size(16);
        display: inline-block;
        margin-right: $padding-reduced-quarter;
        color: $text-grey;
        font-weight: $medium;
        text-transform: none;

        &:last-of-type {
          margin-right: 0;
        }

        &.user-icon {
          @include blog-icon($icon: user, $left: $padding-reduced, $top: 2px);
        }

        &.share-icon {
          @include blog-icon($icon: share-alt, $left: $padding-base-half, $top: -17px);
        }

        &.separator {
          margin: 0 $padding-reduced;
        }
      }
    }

    p {
      @include mq-s-max() {
        @include font-size(16);
        line-height: 22px;
      }

      @include font-size(25);
      line-height: 36px;
      font-weight: $light;
      color: $text-grey;

      &.sa-link-white {
        display: inline-block;
        margin: 0;
        line-height: 16px;
      }
    }
  }

  &__title {
    @include mq-s-max() {
      @include font-size(24);
      line-height: 28px;
    }

    @include font-size(50);
    margin: 0 0 $padding-reduced 0;
    color: $title-grey;
    letter-spacing: 0.4px;
    font-weight: $light;
    line-height: 54px;
  }

  .post-item__meta {
    display: inline-block;
    margin: $padding-reduced-double 0 0 0;
    padding: 0;

    li {
      @include font-size(15);
      display: inline-block;
      margin-right: $padding-reduced-half;
      color: $limmi-blue;
      font-weight: $medium;
      text-transform: uppercase;

      &:last-of-type {
        margin-right: 0;
      }

      &.user-icon {
        @include blog-icon($icon: user, $left: $padding-reduced, $top: 2px);
      }

      &.share-icon {
        @include blog-icon($icon: share-alt, $left: $padding-base-half, $top: -17px);
      }

      &.separator {
        margin: 0 $padding-reduced;
      }
    }
  }
}

.post-detail {
  // neve r use the grid as a container. Use the root element (or Block) as the container, if needed. Carlo
  max-width: $grid-xl;
  width: 100%;
  margin: 0 auto;

  &__header {
    width: 100%;
    text-align: center;
    margin-bottom: $padding-reduced-double;
    padding: 0 $padding-percentage-base;

    h2 {
      @include mq-s-max() {
        @include font-size(24);
        margin: $padding-reduced-half 0 $padding-reduced 0;
      }

      @include font-size(60);
      margin: $padding-reduced-half 0 $padding-reduced-double 0;
    }

    p {
      @include mq-s-max() {
        @include font-size(14);
        line-height: 18px;
      }

      @include font-size(25);
      line-height: 36px;
      font-weight: $light;
      color: $text-grey;
    }

    .post-item__meta {
      margin: $padding-base-section 0 $padding-reduced;

      li {
        margin-right: $padding-reduced-half;
      }
    }
  }

  &__meta,
  &__share {
    display: inline-block;
    margin-top: $padding-reduced-double;
    padding-left: $padding-percentage-base;

    li {
      @include font-size(16);
      display: inline-block;
      margin-right: $padding-reduced-half;
      color: $limmi-blue;
      font-weight: $medium;
      text-transform: uppercase;

      &:last-of-type {
        margin-right: 0;
      }

      &.user-icon {
        @include blog-icon($icon: user, $left: $padding-reduced, $top: 2px);
        line-height: 2;
      }

      &.share-icon {
        @include blog-icon($icon: share-alt, $left: $padding-base-half, $top: -17px);
      }

      &.separator {
        margin: 0 $padding-reduced;
      }
    }
  }

  &__share {
    li {
      color: $text-grey;
      text-transform: none;
    }

    &--list {
      position: relative;
    }
  }

  &__media {
    overflow: hidden;
    border-radius: $border-radius;
    margin-bottom: $padding-reduced-triple;
  }

  &__content {
    padding: 0 $padding-percentage-base;

    h3 {
      margin-top: $padding-reduced-double;
    }
  }

  &__back-link {
    margin-bottom: $padding-reduced-double;
    padding-left: $padding-percentage-base;

    p {
      margin: $padding-reduced 0 0 0;
    }

    a:before {
      @include arrow-link($color: blue, $margin: 0 $padding-reduced-half 0 0, $flip: true);
      vertical-align: middle;
    }
  }

  &__related-posts {
    margin-bottom: $padding-reduced-double;

    h3 {
      margin: $padding-reduced-triple 0 $padding-reduced-double + 10px 0;
      text-align: center;
    }
  }
}

.post-item {
  @include mq-s-max() {
    height: auto;
    padding-bottom: $padding-reduced;
    margin-bottom: $padding-reduced;
  }

  border-radius: $border-radius;
  box-shadow: $shadow-s;
  margin-bottom: $padding-reduced-double;
  background: $white;
  position: relative;
  transition: linear 0.3s;
  padding-bottom: $padding-base + $padding-reduced;

  &__content {
    @include mq-s-max() {
      padding: $padding-reduced;
    }

    padding: $padding-base $padding-reduced;
  }

  &__title {
    @include mq-s-max() {
      @include font-size(16);
      line-height: 19px;
    }

    @include font-size(18);
    color: $title-grey;
    font-family: $secondary-font;
    font-weight: $medium;
    letter-spacing: 0.4px;
    line-height: 1.2;

    a {
      color: $title-grey;
    }
  }

  &__text {
    p {
      @include font-size(16);
      margin: 0;
      line-height: 22px;

      &.sa-link {
        @include mq-s-max() {
          margin-top: $padding-base-half;
        }
        margin-top: $padding-reduced;
      }
    }
  }

  &__share {
    @include mq-s-max() {
      position: relative;
      bottom: 0;
    }
    position: absolute;
    bottom: $padding-base;
    padding: 0 $padding-reduced;

    li {
      @include font-size(16);
      display: inline-block;
      margin-right: $padding-reduced-quarter;
      color: $text-grey;
      font-weight: $medium;
      text-transform: none;

      &:last-of-type {
        margin-right: 0;
      }

      &.user-icon {
        @include blog-icon($icon: user, $left: $padding-reduced, $top: 2px);
        @include mq-s-max() {
          line-height: inherit;
        }
        line-height: 2;

        a:hover {
          color: $limmi-blue;
        }
      }

      &.share-icon {
        @include blog-icon($icon: share-alt, $left: $padding-base-half, $top: -17px);
      }

      &.separator {
        margin: 0 $padding-reduced;
      }
    }
  }

  &__meta {
    line-height: 14px;
    margin-bottom: $padding-reduced-half;

    span {
      @include font-size(14);
      margin-right: $padding-reduced-quarter;
      color: $limmi-blue;
      font-weight: $medium;
      text-transform: uppercase;

      a {
        color: $limmi-blue;

        &:hover {
          opacity: 0.5;
        }
      }

      &:last-of-type {
        margin-right: 0;
      }

      &.user-icon {
        @include blog-icon($icon: user, $left: $padding-reduced, $top: 2px);
        line-height: 2;
      }

      &.share-icon {
        @include blog-icon($icon: share-alt, $left: $padding-base-half, $top: -17px);
      }

      &.separator {
        margin: 0 $padding-reduced;
      }
    }
  }

  &__media {
    overflow: hidden;
  }

  &:hover {
    box-shadow: $shadow-l;
    transform: scale(1.03);
    transition: linear 0.3s;
  }

  header {
    // reduced from styleguide to take font height into account
    margin-bottom: calc($padding-base / 3);
  }
}

.user-icon a {
  color: $text-grey;

  &:hover {
    color: $limmi-blue;
    opacity: 1;
  }
}

@include mq-s-max {
  .post-item-featured-plugin {
    margin-bottom: 0;

    &__body {
      padding: 0;
    }

    &__media {
      width: calc(100% + #{$padding-reduced-double});
      margin-left: -$padding-reduced;
      border-radius: 0;
    }

    &__text__share, // No grandchildren please. Carlo
        .post-item__meta {
      margin-top: $padding-reduced;
    }
  }

  .post-detail {
    &__header {
      padding: 0;
      margin-bottom: 0;
    }

    &__media {
      border-radius: 0;
      margin-bottom: $padding-reduced;
      width: calc(100% + #{$padding-reduced-double});
      margin-left: -$padding-reduced;
    }

    &__content {
      padding: 0;
    }

    &__share {
      margin-top: $padding-reduced;
      padding-left: 0;
    }

    &__back-link {
      margin-bottom: $padding-reduced;
      padding-left: 0;
    }

    .post-item__meta {
      margin-top: 0;
      margin-bottom: $padding-reduced-quarter;
    }
  }
}
