$person-avatar-list-size: 80px;
$person-border: 1px solid $border-grey;

.persons {
  h1 {
    @include mq-s-max() {
      margin-bottom: $padding-reduced;
    }
    text-align: center;
    margin-bottom: $padding-reduced-quadruple;
  }
}

.more-info {
  @include mq-s-max() {
    margin-bottom: $padding-reduced;
  }

  width: 100%;
  text-align: center;
  margin-bottom: $padding-reduced-triple;
}

.persons-filters {
  @include mq-s-max() {
    width: calc(100% + 20px);
    margin-left: -$padding-reduced-half;
  }

  background: $light-blue;
  border-radius: $border-radius;
  padding: $padding-reduced-triple calc($padding-reduced * 6);
  margin-bottom: 50px;

  p {
    margin: 0;
  }

  &__options {
    margin-left: -$padding-reduced-half;
    margin-right: -$padding-reduced-half;

    .choices,
    [type="text"] {
      display: inline;
      float: left;
      width: calc(33.33333% - #{$padding-reduced});
      margin: 0 $padding-reduced-half;
    }

    &--babies {
      .choices,
      [type="text"] {
        width: calc(33.33333% - #{$padding-reduced});
      }
    }

    &:after {
      display: table;
      content: "";
      clear: both;
    }
  }

  &__actions {
    text-align: center;
    margin-top: $padding-reduced-double;
    margin-bottom: 0;

    .btn {
      margin: 0 $padding-reduced-half;
    }

    [type="reset"] {
      background: transparent;
    }

    [type="submit"] {
      position: relative;
    }
  }
}

.persons-letters {
  display: flex;
  justify-content: space-evenly;

  &__item {
    display: inline-block;
  }

  a,
  span {
    @include font-size(30);
    font-family: $primary-font;
    color: $title-grey;
    display: block;
    line-height: 1;
    padding: 0 5px;
  }

  a:hover {
    color: $limmi-blue;
    opacity: 1;
  }

  span {
    color: $border-grey;
  }
}

.persons-list {
  margin-bottom: $padding-reduced-quadruple;

  &__letter {
    position: relative;
    display: block;
    margin: $person-avatar-list-size auto 50px;
    background: $light-grey;
    width: $padding-reduced-triple;
    height: $padding-reduced-triple;
    border-radius: $padding-reduced-double;

    span {
      @include font-size(28);
      color: $title-grey;
      font-family: $primary-font;
      position: absolute;
      line-height: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.persons-list-container {
  @include within-grid;
}

.person {
  @include mq-s-max {
    min-height: $padding-incremented * 4;
    padding-top: $padding-base-half;
    padding-bottom: $padding-base-half;
  }

  position: relative;
  border: $person-border;
  border-top: 0;
  overflow: hidden;

  &:after {
    @include arrow-link($color: blue, $margin: 0 $padding-reduced-half 0 0);
    position: absolute;
    right: $padding-reduced;
    top: 50%;
    transform: translateY(-50%);
  }

  &:nth-child(1) {
    border-top-left-radius: $half-border-radius;
    border-top: $person-border;
  }

  &:nth-child(2) {
    border-top-right-radius: $half-border-radius;
    border-top: $person-border;
  }

  &:nth-child(even) {
    border-left: 0;
  }

  &:last-child:nth-child(even) {
    border-bottom-right-radius: $half-border-radius;
  }

  &:last-child:nth-child(odd) {
    border-bottom-right-radius: $half-border-radius;
    border-bottom-left-radius: $half-border-radius;
  }

  &:nth-last-child(2):nth-child(even) {
    border-bottom-right-radius: $half-border-radius;
  }

  &:nth-last-child(2):nth-child(odd) {
    border-bottom-left-radius: $half-border-radius;
  }

  &__link {
    @include mq-s-max {
      min-height: 85px;
    }

    display: flex;
    height: 100%;
    padding: $padding-base $padding-reduced-triple $padding-base $padding-base;
    min-height: $person-avatar-list-size + $padding-base * 2;
    align-items: center;
  }

  &:hover {
    background-color: $dove-blue;

    a {
      opacity: 1;
    }

    &:after {
      opacity: 0.5;
    }
  }

  &__picture {
    border-radius: $person-avatar-list-size * 2;
    width: $person-avatar-list-size;
    height: $person-avatar-list-size;
    overflow: hidden;

    &--nope {
      border: $person-border;
      overflow: hidden;
      text-align: center;
      line-height: $person-avatar-list-size;

      img {
        width: 50%;
        height: 50%;
        vertical-align: middle;
      }
    }
  }

  &__info {
    float: left;
    margin-left: $padding-reduced;
    width: 70%;
  }

  &__clinic {
    @include category-date();
    display: block;
  }

  &__title {
    @include font-size(18);
    color: $title-grey;
    font-weight: 500;
  }

  &__main-role {
    @include font-size(16);
    display: block;
    color: $text-grey;
  }

  &-teaser .description {
    padding: 0 $padding-reduced;

    p {
      @include font-size(16);
    }
  }
}

.person-baby {
  height: auto;
  padding-bottom: $padding-reduced;

  &__gender {
    @include font-size(18);
    display: block;
    position: absolute;
    top: 200px - calc($generic-round-button-size / 2);
    left: 50%;
    transform: translateX(-50%);
    background: $limmi-blue;
    color: $white;
    width: $generic-round-button-size;
    height: $generic-round-button-size;
    line-height: calc($generic-round-button-size + 5px);
    text-align: center;
    border-radius: calc($generic-round-button-size / 2);
    z-index: 9;
  }

  a:hover {
    opacity: 1;
  }

  &__header {
    margin-top: calc($generic-round-button-size / 2) + $padding-base-half !important;
  }

  &__name {
    padding-top: calc($padding-base / 2);
  }

  &__date-of-birth,
  &__size {
    @include font-size(16);
    margin: 0 $padding-reduced;
  }

  &__date-of-birth {
    margin-top: calc($padding-reduced / 3);
  }

  &__actions {
    @include font-size(16);
    color: $text-grey;
    margin: $padding-reduced $padding-reduced 0 $padding-reduced;

    .share-alt-icon,
    .email-icon {
      @include i-icon-font-closer;
    }
  }

  &__actions-separator {
    margin: 0 calc($padding-reduced / 3);
  }
}

.baby-detail,
.person-detail {
  &__media {
    @include mq-s-max {
      padding: 0 $padding-reduced;
    }

    text-align: center;

    img {
      border-radius: $border-radius;
      overflow: hidden;
      z-index: 1;
      position: relative;
    }
  }

  &__header {
    padding: $padding-reduced-quadruple $padding-reduced-double;
    background-color: $light-blue;
    position: relative;
    text-align: center;

    &--with-picture {
      padding-top: 200px;
      top: -150px;
      margin-bottom: -150px;
    }
  }

  &__title {
    position: relative;
    padding-bottom: $padding-reduced-double;
    margin-bottom: $padding-reduced-double;

    &:before {
      position: absolute;
      height: 2px;
      width: $person-avatar-list-size;
      background: $limmi-blue;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      content: "";
    }
  }

  &__roles,
  &__phone,
  &__date-of-birth,
  &__size {
    @include font-size(24);
    margin: 0;
    line-height: 1.5;
  }

  &__actions {
    margin-top: $padding-reduced;

    .email-icon,
    .phone-icon {
      @include i-icon-font-closer;
      line-height: 1;

      a {
        color: $text-grey;
      }

      &:before {
        line-height: 1;
      }
    }
  }

  &__contacts {
    margin-top: $padding-reduced;
  }

  &__action {
    @include font-size(16);
    color: $limmi-blue;
    width: $generic-round-button-size;
    height: $generic-round-button-size;
    line-height: $generic-round-button-size - $generic-round-button-border;
    text-align: center;
    border: $generic-round-button-border solid $limmi-blue;
    display: inline-block;
    border-radius: calc($generic-round-button-size / 2);
    margin: 0 calc($padding-reduced / 3);

    i {
      @include font-size(20);
    }
  }

  &__ecard {
    margin-top: $padding-reduced-quadruple;
    margin-bottom: $padding-reduced-double;

    [type="submit"] {
      position: relative;
    }
  }

  &__description {
    @include more-space-between-plugins();
    margin-top: $padding-reduced-double;
    margin-bottom: $padding-reduced-double;

    table {
      // Need important here to override a WYSIWIG default
      width: 100% !important;
    }

    td {
      padding-bottom: $padding-reduced;

      &:first-child {
        width: 20%;
        vertical-align: top;
      }
    }

    tr:last-child {
      td {
        padding-bottom: 0;
      }
    }
  }

  &__back {
    margin-top: $padding-reduced-double;
    margin-bottom: $padding-reduced-quadruple;
  }

  &__back-link {
    color: $limmi-blue;

    &:visited {
      color: $limmi-blue;
    }

    &:before {
      @include arrow-link($color: blue, $margin: 0 $padding-reduced-half 0 0);
      transform: rotate(180deg);
    }
  }
}

// The breadcrumbs is different for baby and persons detail pages

.page--baby-detail,
.page--person-detail {
  .breadcrumbs:after {
    display: none;
    visibility: hidden;
  }
}

@include mq-s-max() {
  .persons-filters {
    padding: $padding-reduced;
    margin-left: -$padding-reduced;
    margin-bottom: $padding-incremented-half;
    border-radius: 0;
    position: relative;
    width: calc(100% + #{$padding-reduced-double});

    &__options {
      flex-wrap: wrap;
      margin-left: 0;
      margin-right: 0;

      .choices,
      [type="text"] {
        flex: 1 1 100%;
        width: 100%;
        margin: 0 0 $padding-reduced-half 0;
      }
    }

    &__actions {
      margin-top: 0;
      margin-bottom: 0;
    }

    .btn {
      margin: 0;
      width: 100%;

      + .btn {
        margin-top: $padding-reduced-half;
      }
    }
  }

  .persons-letters {
    flex-wrap: wrap;
    justify-content: center;

    &__item {
      margin-bottom: $padding-reduced-half;
    }

    a,
    span {
      @include font-size(22);
    }
  }

  .persons-list-container {
    border: 0;
    border-radius: 0;
    padding: 0 calc($padding-reduced / 2);
    width: calc(100% - #{$padding-reduced});
  }

  .persons-list {
    margin-top: $padding-reduced;

    &__letter {
      margin-top: $padding-incremented-half;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: $padding-incremented-half;
      width: $padding-base-section-content;
      height: $padding-base-section-content;
      line-height: $padding-base-section-content;

      span {
        @include font-size(22);
      }

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .person {
    border: $person-border;

    &:nth-child(n + 2),
    &:nth-last-child(2):nth-child(n) {
      border-radius: 0;
      border-top: 0;
    }

    &:first-child {
      border-radius: $half-border-radius $half-border-radius 0 0;
      border-top: $person-border !important;
    }

    &:nth-child(even) {
      border-left: $person-border;
    }

    &:last-child {
      border-bottom: $person-border;
      border-radius: 0 0 $half-border-radius $half-border-radius;
    }

    &__title {
      @include font-size(16);
    }

    &__main-role {
      @include font-size(14);
    }

    &-detail__roles {
      @include font-size(16);
      font-weight: $light;
    }

    &__link {
      padding: 0;
    }

    &__info {
      width: 60%;
      position: absolute;
      top: 50%;
      left: $person-avatar-list-size;
      transform: translateY(-50%);
      margin: 0;
    }

    &-detail__back {
      margin: 0;
    }

    &__picture {
      position: absolute;
      width: 50px;
      height: 50px;
      top: 50%;
      transform: translateY(-50%);

      &--nope img {
        vertical-align: super;
      }
    }

    &:after {
      margin: 0;
      right: $padding-base-half;
    }

    &-baby {
      height: auto;
      margin-bottom: $padding-reduced;
      padding-bottom: 20px;
    }
  }
}
