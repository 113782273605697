.cookiebanner {
  background: $dove-blue;
  padding: $padding-reduced;
  color: $cookie-blue;
  position: fixed;
  top: 70%;
  left: 0;
  width: 310px;
  height: 190px;
  opacity: 1;
  z-index: 999;
  box-shadow: $shadow-s;

  &-close {
    display: none;
  }

  .btn {
    display: block;
    margin: $padding-reduced 0;

    &:after {
      content: none;
    }
  }
}
