section {
  position: relative;

  .grid {
    position: relative;
  }
}

// These selectors are used in pages like Home - Uber uns
// and will adjust the padding in specific cases of sections after a section without a grid

@mixin padding-exception-section-without-grid {
  @include mq-s-max {
    padding-top: $padding-base-section;
  }
  padding-top: $padding-incremented-double;
}

.page-container {
  .widget-section--no-grid:first-child .widget-section__header,
  nav + .widget-section--no-grid + .widget-section,
  .widget-section--no-grid:first-child + .widget-section {
    @include padding-exception-section-without-grid;
  }
}

.widget-section {
  padding-top: $padding-base-section;
  padding-bottom: $padding-base-section;

  &__header {
    @include mq-s-max {
      margin-bottom: $padding-reduced;
    }
    margin-bottom: $padding-base-section;
    text-align: center;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 1;
    }
  }

  // Sections modifiers
  //&--grid {
  //}

  &--no-grid {
    padding-bottom: 0;
  }

  //&--absolute {
  //}

  &--white {
    @include mq-s-max {
      padding-top: $padding-base;
      padding-bottom: $padding-base;
    }
    padding-top: $padding-incremented-double;
    padding-bottom: $padding-incremented-double;

    + .widget-section--white {
      @include mq-s-max {
        padding-bottom: $padding-reduced;
      }
      padding-top: 0;
      padding-bottom: $padding-reduced-quadruple;
    }

    + .widget-section--no-grid,
    + .widget-section--karriere {
      padding-top: 0;
    }
  }

  &--blue {
    @include mq-s-max {
      padding-top: $padding-reduced;
      padding-bottom: $padding-reduced;
    }
    background-color: $light-blue;

    padding-top: $padding-incremented;
    padding-bottom: $padding-incremented;

    + .widget-section--blue {
      padding-top: 0;
      padding-bottom: $padding-reduced-quadruple;
    }
  }

  &--blue-half {
    @include mq-s-max {
      padding-top: $padding-reduced-half;
    }
    background-color: $light-blue;
    padding-bottom: $padding-base;

    &:before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 165px;
      background-color: $white;
    }
  }

  &--grey {
    @include mq-s-max {
      padding-top: $padding-reduced;
      padding-bottom: $padding-reduced;
    }
    background-color: $dove-blue;
    padding-top: $padding-base-section * 2;
    padding-bottom: $padding-reduced-quintuple;

    + .widget-section--grey {
      padding-top: 0;
      padding-bottom: $padding-reduced-quadruple;
    }
  }

  &--higher {
    padding-top: $padding-higher-section;
    padding-bottom: $padding-reduced-quintuple;
  }
}

// Sections exceptions and specific rules

main {
  .widget-section:first-of-type:not(.widget-section--blue) {
    padding-top: 0;
  }

  .breadcrumbs ~ .widget-section:first-of-type {
    @include mq-s-max {
      padding-top: 0;
    }
    padding-top: $padding-base-section;
  }
}

.teaser-slider {
  .slick-paginator {
    top: 100%;
  }

  .widget-text__teaser {
    margin: 0 0 $padding-reduced;
    padding: 10px 20px;
    height: auto;
  }
}
