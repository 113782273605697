.inner-menu {
  position: relative;
  display: block;
  width: calc(100% - 60px); // Taken from the design
  background: $white;
  margin: 0 auto;
  border-radius: $border-radius;
  padding: $padding-reduced $padding-base;
  box-shadow: $shadow-s;
  transform: translateY(-50%);

  &__items {
    display: inline-block;
    margin-left: -$padding-reduced-half;
  }

  &__item {
    display: inline-block;
    margin: 0 $padding-reduced-half 0;
    color: $text-grey;

    &:visited {
      color: $text-grey;
    }

    &.current {
      font-weight: $bold;
    }
  }

  &__title {
    @include font-size(18);
    display: inline-block;
    width: auto;
    margin: 0 30px 0 0;
    font-weight: $demibold;

    a {
      @include font-size(18);
      font-family: $primary-font;
      color: $title-grey;

      &:visited {
        color: $title-grey;
      }
    }
  }

  &__title-icon {
    display: none;
  }

  &__separator {
    display: inline;
    visibility: visible;
    color: $text-grey;
  }
}

@include mq-s-max() {
  .inner-menu {
    width: 100%;
    padding: 0 $padding-reduced;
    left: 0;
    transform: translateY(-$padding-reduced);
    z-index: 2;

    &__items {
      margin-left: 0;
    }

    &__title {
      display: block;
      width: 100%;
      padding: $padding-reduced-half 0;
      line-height: 2;
    }

    &__title-icon {
      display: block;
      position: absolute;
      right: $padding-reduced;
      top: $padding-reduced-half;
      color: $limmi-blue;
    }

    &__separator {
      display: none;
      visibility: hidden;
    }

    &__items {
      display: none;
      visibility: hidden;
      background: $white;
    }

    &__item {
      margin: 0;
      padding: $padding-reduced-half 0;
      display: block;
      width: 100%;
      border-top: 1px solid $light-grey;
      line-height: 2;

      &:after {
        float: right;
        width: $padding-reduced;
        height: $padding-reduced;
        background: url("../img/icons/pfeil_blau.png") no-repeat;
        background-size: contain;
        content: "";
        position: relative;
        top: 6px;
        pointer-events: none;
      }
    }

    + .widget-image-text__text-container {
      margin-top: $padding-reduced-triple;
    }
  }

  .inner-menu.active {
    .inner-menu__items {
      display: block;
      visibility: visible;
    }

    .inner-menu__title-icon {
      transform: rotate(180deg);
    }
  }
}
