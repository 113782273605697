.event-detail {
  &__content-box {
    width: 100%;
    position: relative;
    margin-top: $padding-reduced-double;

    td {
      vertical-align: initial;

      &:first-of-type {
        width: 33%;
      }
    }

    h5 {
      margin: 0;
    }

    i + i {
      margin-left: $padding-reduced-double;
    }
  }

  .widget-text__header h3 {
    margin: $padding-reduced-double 0 $padding-reduced 0;
  }
}
