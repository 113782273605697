// this removes the footer buttons magic number
$anim-duration: 0.2s;
$tab-content-size: 80%;
$tab-content-size-invert: 100% - $tab-content-size;

.ui-helper-hidden-accessible {
  display: none;
}

.bottomtabs {
  position: fixed;
  width: 100%;
  height: 0;
  bottom: 0;
  z-index: 99;

  &.submenu-overlay {
    z-index: 0;
  }

  &__header {
    @include within-grid;

    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: bottom $anim-duration;
    z-index: 1;

    // The quick access bar shadow
    &:before {
      content: "";
      border-radius: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      width: 90%;
      height: 20px;
      box-shadow: 0 0 30px rgba($black, 0.4);
      z-index: -1;
      transform: translateX(-50%);
    }

    &:after {
      clear: both;
      display: table;
      content: "";
    }
  }

  &__content {
    position: fixed;
    height: 0;
    width: 100%;
    overflow: auto;
    bottom: 0;
    background-color: $light-blue;
    transition: height $anim-duration;

    // Carlo TODO: this can be written better
    > .hidden {
      display: none;
      visibility: hidden;
    }

    @include mq-s-max() {
      tr {
        display: block;
        padding: 0 $padding-reduced;

        &:last-child {
          td {
            border: 0;
          }
        }
      }

      td {
        float: left;
        width: 100%;
        height: auto;
        margin: 0;
        border-bottom: 1px solid $border-grey;
        padding: $padding-reduced 0;

        p {
          margin: 0;
          padding: 0;

          br {
            display: none;
          }
        }

        h3 {
          margin: 0;
          padding: 0;
        }
      }
    }

    h2,
    h3,
    h4,
    h5,
    p {
      @include mq-s-max() {
        padding: 0;
      }

      padding: 0 $padding-percentage-base;
    }

    form {
      @include mq-s-max() {
        margin: $padding-reduced $padding-reduced - 5px; // The form here hasn't the columns, to fit to other content he needs this margin
        width: 100%;
        padding: 0; // Inset padding is not required, margin makes form content fit with the rest of the elements
      }

      padding: $padding-reduced-quarter $padding-percentage-base 0 $padding-percentage-base;
      width: calc(100% - #{$gutter}); // The form here hasn't the columns, so we need to adjust its width
      margin: $padding-reduced-double auto;
    }

    table {
      @include mq-s-max() {
        table-layout: fixed;
        margin: $padding-reduced 0;
      }

      width: 100%;
      margin: $padding-reduced-double 0;
      background: $white;
      border-radius: $border-radius;

      &.seamless {
        @include mq-s-max() {
          margin: 0;
        }

        margin: $padding-reduced-double $padding-percentage-base;
        background: transparent;
        padding: 0;
        max-width: 500px;

        td {
          &:first-child {
            @include mq-s-max() {
              padding-top: $padding-reduced;
            }
          }

          border-bottom: 0;
          padding: 0;
        }

        tr {
          @include mq-s-max() {
            padding: 0;
          }
        }
      }
    }

    table:not(.seamless) {
      td {
        @include mq-s-max() {
          width: 100%;
        }

        width: 50%;
        padding: 0 $padding-percentage-base;

        &:nth-child(odd) {
          padding-right: $padding-base-half;
        }

        &:nth-child(even) {
          @include mq-s-max() {
            padding: $padding-reduced;
            padding-left: 0;
          }
          padding-left: $padding-base-half;
        }

        // Reset margins inside table
        > * {
          padding: 0;
          margin: 0;
        }
      }

      tr {
        + tr td {
          @include mq-s-max() {
            padding: $padding-reduced;
            padding-left: 0;
          }

          padding-top: 45px; // The space between rows
        }

        &:first-child td {
          @include mq-s-max() {
            padding: $padding-reduced;
            padding-left: 0;
          }

          padding-top: $padding-incremented; // The padding top of the white table
        }

        &:last-child td {
          @incldue mq-s-max() {
            padding-bottom: $padding-reduced;
          }

          padding-bottom: $padding-incremented; // The padding bottom of the white table
        }
      }
    }

    .white {
      padding: $padding-reduced 0;
      margin-bottom: $padding-reduced-triple;
      background: $white;
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }

    .widget-raw-html {
      margin-bottom: $gutter;

      .google-map {
        overflow: hidden;
        padding-bottom: 56.25%;
        position: relative;
        height: 0;

        iframe {
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          position: absolute;
        }
      }
    }
  }

  &__content-item {
    padding-top: $padding-reduced-quadruple;
  }

  &__button {
    @extend .button__text;

    @include mq-s-max() {
      border-radius: 0;
      padding: $padding-reduced-half;
      border-right: 1px solid $border-grey;
      font-weight: $regular;
      min-height: auto;
    }

    width: 33.33333%;
    display: block;
    float: left;
    padding: calc($padding-base / 2);
    max-height: 50px;
    background: $light-grey;
    color: $white;
    text-align: center;

    &:first-child {
      @extend .icon-envelope;
      border-top-left-radius: $border-radius;
      color: $text-grey;

      &:before {
        @include font-size(22);
        vertical-align: middle;
        margin-right: $padding-reduced-half;
      }

      @include mq-s-max() {
        border-radius: 0;

        &:before {
          @include font-size(17);
          margin: 5px;
        }
      }
    }

    &:nth-child(even) {
      background: $limmi-blue;

      span:before {
        @extend .icon-magnifier;
        @include font-size(22);
        vertical-align: middle;
        margin-right: $padding-reduced-half;
      }

      @include mq-s-max() {
        background: $light-grey;
        color: $text-grey;

        span:before {
          @include font-size(17);
          margin: 2px 5px 0 0;
        }
      }

      &:visited {
        @include mq-m() {
          color: $white;
        }
      }

      &.active {
        color: $limmi-blue;
      }
    }

    &:last-child {
      border-top-right-radius: $border-radius;
      color: $red;

      .plus-sign {
        @include font-size(22);
        line-height: 0;
        margin-right: $padding-reduced-half;
        vertical-align: middle;
      }

      @include mq-s-max() {
        border-radius: 0;
        border: 0;

        .plus-sign {
          margin-right: 5px;
        }
      }
    }

    &.active {
      color: $limmi-blue;
      background: $light-blue;
      box-shadow: 0 6px 10px -4px rgba($black, 0.2);
    }

    span {
      pointer-events: none;
    }

    &:hover {
      opacity: 1;
    }
  }

  &__close {
    @include font-size($padding-base);
    position: fixed;
    width: $padding-base;
    height: $padding-base;
    top: calc(100% + #{$padding-base}); // send it off screen when not active
    right: $padding-incremented;
    cursor: pointer;
    z-index: 999;
    background-color: transparent;

    svg {
      // Be sure that the svg icon is 30x30
      width: 100%;
      height: 100%;

      .st0 {
        fill: $text-grey;
      }
    }

    &:hover svg .st0 {
      fill: $limmi-blue;
    }
  }

  &__search {
    @include mq-s-max() {
      width: 100%;
    }

    &-title {
      // Making this h1-like
      @include mq-s-max() {
        @include font-size(30);
        margin-bottom: $padding-reduced;
      }

      @include font-size(60);
      margin-bottom: $padding-base-section-content;
    }

    &-input-container {
      @include mq-s-max() {
        margin-left: 0;
        max-width: 100%;
        width: 100%;
      }
      position: relative;
      max-width: 83.3%;
      margin-left: $padding-percentage-base;
    }

    &-input {
      @include mq-s-max() {
        @include font-size(18);
        border-radius: $half-border-radius;
        height: $padding-incremented;
        padding: $padding-reduced - 5px $padding-reduced;
      }

      @include font-size(26);
      border-radius: $border-radius;
      padding: 23px 80px 23px 45px;
      color: $text-grey;
      border-color: $border-grey;
      height: $padding-reduced-quadruple;
    }

    .autocomplete__list--dropdown {
      @include mq-s-max() {
        border-radius: $half-border-radius;
      }

      display: none;
      z-index: 1;
      background-color: $choices-bg-color-dropdown;
      border: 1px solid $border-grey;
      margin-top: -1px;
      overflow: hidden;
      word-break: break-all;
      border-radius: $border-radius;
      margin-bottom: $padding-reduced-quadruple;

      &.is-active {
        display: block;
      }

      .is-open & {
        border-color: darken($choices-keyline-color, 15%);
      }

      .is-flipped & {
        top: auto;
        bottom: 100%;
        margin-top: 0;
        margin-bottom: -1px;
        border-radius: 0.25rem 0.25rem 0 0;
      }

      .autocomplete__list {
        position: relative;
        max-height: 300px;
        overflow: auto;
        will-change: scroll-position;
      }

      .autocomplete__item {
        @include mq-s-max() {
          padding: 15px $padding-reduced;
        }

        position: relative;
        padding: $padding-reduced 45px;
        color: $text-grey;
        border-bottom: 1px solid $dove-blue;

        &:last-child {
          border-bottom: 0;
        }

        .category {
          @include category-date;
          width: 100%;
          display: block;
        }

        &:hover,
        &__hovered {
          background: $dove-blue;
        }

        [dir="rtl"] & {
          text-align: right;
        }
      }

      .autocomplete__item--selectable {
        @media (min-width: 640px) {
          &:after {
            content: attr(data-select-text);
            opacity: 0;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
          }

          [dir="rtl"] & {
            text-align: right;
            padding-right: 10px;

            &:after {
              right: auto;
              left: 10px;
            }
          }
        }

        &.is-highlighted {
          background-color: $dove-blue;
        }
      }
    }

    .autocomplete__item {
      position: relative;
      padding: $padding-reduced 45px;
      color: $text-grey;
      border-bottom: 1px solid $dove-blue;

      &:last-child {
        border-bottom: 0;
      }

      .category {
        width: 100%;
        display: block;
      }

      &:hover,
      &__hovered {
        background: $dove-blue;
      }

      [dir="rtl"] & {
        text-align: right;
      }
    }

    div {
      position: relative;
    }

    .search-icon {
      @extend .icon-magnifier;

      @include mq-s-max() {
        right: $padding-reduced;
        top: $padding-reduced-quarter - 1px;
      }

      @include font-size(26);
      position: absolute;
      right: 45px;
      top: 18px;
      color: $text-grey;

      &:before {
        vertical-align: middle;
      }
    }
  }
}

.bottomtabs.active {
  @include mq-s-max() {
    z-index: 999;
  }

  height: 100%;
  background: rgba(0, 0, 0, 0.6);

  .bottomtabs__header {
    @include mq-s-max() {
      display: none;
    }

    bottom: $tab-content-size;
  }

  .bottomtabs__close {
    @include mq-s-max() {
      position: absolute;
      right: $padding-reduced;
      top: $padding-reduced;
    }

    top: calc(#{$tab-content-size-invert} + #{$padding-base});
  }

  .bottomtabs__content {
    @include mq-s-max() {
      height: 100%;
    }

    height: $tab-content-size;
  }
}

.bottom-tabs-opened {
  // To remove scrollbar from the body
  overflow: hidden;
}

.bottomtabs__content {
  // This is a way to style the widget text under the googlemap differently,
  // it is a hackish way to avoid a new template. Carlo
  .widget-googlemap-plugin {
    @include mq-s-max() {
      margin-top: $padding-reduced;
    }

    line-height: 0;
    margin-top: $padding-base-section-content;

    + .widget-text {
      @include mq-s-max() {
        padding: $padding-reduced-double 8.3%;
      }

      background: $white;
      border-radius: 0 0 $border-radius $border-radius;
      padding: $padding-reduced-double 0;
      width: calc(100% - #{$padding-base});
      margin: 0 auto $padding-base-section-content auto;
      z-index: 9;
      box-shadow: $shadow-s;

      [class$="-icon"]:before {
        position: relative;
      }

      header + .wysiwyg {
        @include mq-s-max() {
          margin-top: $padding-base-half + $line-height-correction-offset;
        }
        margin-top: $padding-reduced-double;
      }
    }
  }

  .djangocms-googlemap-container {
    border-radius: $border-radius $border-radius 0 0;
    box-shadow: $shadow-s;
  }
}
