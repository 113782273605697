////////////////////////////////////////////////////////////////////////////////
// Typography
////////////////////////////////////////////////////////////////////////////////

h1,
h2,
h3,
h4,
h6 {
  width: 100%;
  margin: 0;
  color: $title-grey;
  font-family: $primary-font;
  font-weight: $light;
}

h1 {
  @include mq-s-max() {
    @include font-size(28);
    line-height: 33px;
  }

  @include font-size(60);
  line-height: 62px;
  text-align: center;
}

h2 {
  @include mq-s-max() {
    @include font-size(24);
    line-height: 28px;
  }

  @include font-size(50);
  line-height: 54px;
}

h3 {
  @include mq-s-max() {
    @include font-size(22);
    line-height: 26px;
  }

  @include font-size(40);
  line-height: 42px;
}

h4 {
  @include mq-s-max() {
    @include font-size(18);
    line-height: 22px;
  }

  @include font-size(30);
  font-weight: $regular;
  line-height: 36px;
}

h5 {
  @include mq-s-max() {
    @include font-size(16);
    line-height: 20px;
    margin: 0 0 $padding-reduced - 5px 0;
  }

  @include font-size(26);
  width: 100%;
  color: $text-grey;
  font-family: $secondary-font;
  font-weight: $light;
  line-height: 34px;
}

h6 {
  @include mq-s-max() {
    @include font-size(16);
    line-height: 20px;
    letter-spacing: 0.6px;
  }

  @include font-size(18);
  font-weight: $medium;
  line-height: 24px;
  letter-spacing: 0.4px;
}

p {
  @include mq-s-max() {
    @include font-size(14);
    letter-spacing: 0.4px;
    line-height: 18px;
  }

  @include font-size(18);
  margin: $padding-reduced 0;
  color: $text-grey;
  font-family: $secondary-font;
  font-weight: $regular;
  letter-spacing: 0.2px;
  line-height: 26px;
}

@mixin footer-text {
  @include mq-s-max() {
    @include font-size(14);
    letter-spacing: 0.35px;
    line-height: 20px;
  }

  @include font-size(18);
  margin: 0 0 $padding-reduced;
  color: $text-grey;
  font-family: $secondary-font;
  font-weight: $regular;
  letter-spacing: 0.2px;
  line-height: 30px;

  a {
    text-decoration: none !important;
  }
}

@mixin teaser-title {
  @include mq-s-max() {
    @include font-size(16);
    line-height: 19px;
  }

  @include font-size(18);
  color: $title-grey;
  font-family: $secondary-font;
  font-weight: $medium;
  letter-spacing: 0.4px;
  line-height: 24px;
}

@mixin category-date {
  @include mq-s-max() {
    @include font-size(12);
    letter-spacing: 0.51px;
    line-height: 20px;
  }

  @include font-size(14);
  color: $limmi-blue;
  font-family: $primary-font;
  font-weight: $medium;
  letter-spacing: 0.6px;
  line-height: 20px;
  text-transform: uppercase;
}

@mixin form-label {
  @include mq-s-max() {
    @include font-size(14);
    letter-spacing: 0.17px;
  }

  @include font-size(16);
  color: $text-grey;
  font-family: $secondary-font;
  font-weight: $medium;
  letter-spacing: 0.2px;
}

@mixin form-text {
  @include mq-s-max() {
    @include font-size(14);
    letter-spacing: 0.17px;
  }

  @include font-size(18);
  color: $text-grey;
  font-family: $secondary-font;
  font-weight: $regular;
  letter-spacing: 0.2px;
}

////////////////////////////////////////////////////////////////////////////////
// Typography - Classes
////////////////////////////////////////////////////////////////////////////////
.teaser {
  &__title {
    @include teaser-title;

    &--red {
      color: $red;
    }
  }

  &__text {
    p {
      @include mq-s-max() {
        @include font-size(14);
        letter-spacing: 0.3px;
        line-height: 18px;
      }

      @include font-size(16);
      color: $text-grey;
      font-family: $secondary-font;
      font-weight: $regular;
      letter-spacing: 0.4px;
      line-height: 22px;

      &.sa-link {
        @extend .sa-link;
        margin-top: $padding-reduced-double;
      }

      &.sa-link + .sa-link {
        margin-top: $padding-reduced !important;
      }
    }
  }
}

.widget-file__link {
  @include font-size(16);
  font-weight: $medium;
}

.breadcrumbs {
  @include mq-s-max() {
    @include font-size(11);
    letter-spacing: 0.7px;
    line-height: 20px;
  }

  @include font-size(14);
  color: $title-grey;
  font-family: $primary-font;
  font-weight: $medium;
  letter-spacing: 0.6px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
}

.footer__text {
  @include mq-s-max() {
    @include font-size(14);
    letter-spacing: 0.35px;
    line-height: 20px;
  }

  @include font-size(18);
  color: $text-grey;
  font-family: $secondary-font;
  font-weight: $light;
  letter-spacing: 0.2px;
  line-height: 30px;
}

.button__text {
  @include font-size(14);
  font-family: $secondary-font;
  font-weight: $bold;
  letter-spacing: 0.8px;
  text-transform: uppercase;

  &--blue {
    color: $limmi-blue;
  }

  &--white {
    color: $white;
  }
}

.sa-link {
  $link-max-width: 85%;

  @include mq-s-max() {
    @include font-size(14);
    letter-spacing: 0.35px;
    line-height: 1;
  }

  @include font-size(16);
  letter-spacing: 0.4px;
  line-height: 1;
  margin-top: $padding-reduced-double;
  font-family: $primary-font;
  font-weight: $medium;

  &:before {
    @include arrow-link($color: blue, $margin: 0 $padding-reduced-half 0 0);
  }

  a {
    color: $limmi-blue;
    display: inline-block;
    max-width: $link-max-width;
    vertical-align: top;
  }

  &-download {
    margin-bottom: $padding-reduced-half;

    a {
      color: $limmi-blue;

      &:before {
        @include icon-download;
        vertical-align: middle;
      }

      span {
        display: inline-block;
        max-width: $link-max-width;
        vertical-align: top;
      }
    }
  }
}

.sa-link-white {
  @extend .sa-link;

  a {
    &:before {
      @include arrow-link($color: white, $margin: 0 0 -2px $padding-reduced-half);
    }
  }
}

////////////////////////////////////////////////////////////////////////////////
// Typography - CKEditor Stiles
////////////////////////////////////////////////////////////////////////////////

.text {
  &--evidence {
    @include font-size(60);
    color: $limmi-blue;
  }

  &--big {
    @include font-size(80);
    color: $limmi-blue;
  }
}
