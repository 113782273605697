// sass-lint:disable no-duplicate-properties
@mixin margin($size1, $size2: null, $size3: null, $size4: null) {
  margin: calulate-px($size1) calulate-px($size2) calulate-px($size3) calulate-px($size4);
  margin: calculate-rem($size1) calculate-rem($size2) calculate-rem($size3) calculate-rem($size4);
}

@mixin font-size($size) {
  font-size: $size + px;
  font-size: calculate-rem($size);
}

// sass-lint:enable no-duplicate-properties

%image-vertical-aligned {
  &:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}

%centered-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

%display-flex {
  display: flex;
}

@mixin arrow-link($color, $margin, $flip: false) {
  display: inline-block;
  height: 0.7em;
  width: 1.2em;
  margin: $margin;
  background: url("/static/img/icons/arrow_#{$color}.svg") no-repeat;
  background-size: contain;
  content: "";
  pointer-events: none;
  @if $flip {
    transform: scaleX(-1);
  }
}

@mixin icon-download() {
  display: inline-block;
  height: 1em;
  width: 1.2em;
  margin-right: $padding-reduced-half;
  background: url("/static/img/icons/download.svg") no-repeat;
  background-size: contain;
  background-position: center;
  content: "";
}

@mixin blog-icon($icon, $left, $top) {
  @extend .icon-#{$icon};
  position: relative;
  left: $left;
  padding-left: $padding-reduced-half;

  &:before {
    @include font-size(20);
    position: absolute;
    left: -$padding-reduced;
    top: $top;
    color: $title-grey;
  }

  &:hover {
    color: $limmi-blue;
    cursor: pointer;

    &:before {
      color: $limmi-blue;
    }
  }
}

@mixin i-icon-font() {
  font-style: normal;
  position: relative;
  display: inline-flex;
  color: $text-grey;

  &:before {
    @include mq-s-max {
      margin-right: $padding-reduced-half;
    }
    vertical-align: bottom;
    line-height: 1.2em;
    margin-right: $padding-reduced;
  }

  &:hover {
    color: $limmi-blue;
    cursor: pointer;

    &:before {
      opacity: 0.5;
    }

    a {
      color: $limmi-blue;
      cursor: pointer;
    }
  }

  a {
    text-decoration: none !important;
    color: $text-grey;

    &:hover {
      color: $limmi-blue;
      cursor: pointer;
    }

    &:visited {
      color: $text-grey;
      cursor: pointer;
    }
  }
}

@mixin i-icon-font-closer() {
  &:before {
    margin-right: $padding-base-half;
  }
}

@mixin last-p-no-margin {
  > p:last-child {
    margin-bottom: 0;
  }
}

@mixin teaser-hover {
  &:hover {
    cursor: pointer;
    box-shadow: $shadow-l;
    transform: scale(1.03);
    transition: linear 0.3s;
  }
}

@mixin fullwidth-breaking-grid {
  width: calc(100% + #{$padding-reduced-double});
  margin-left: -$padding-reduced;
  padding-left: $padding-reduced;
  padding-right: $padding-reduced;
}

@mixin section-absolute-content {
  @include mq-s-max {
    margin-bottom: $padding-incremented-half;
  }

  @include mq-m-max {
    @include fullwidth-breaking-grid;
    padding: 0 $padding-percentage-base 50px;

    h5 {
      @include font-size(18);
      line-height: $line-height-headings;
    }
  }

  @include mq-l {
    position: absolute;
    padding: 0 $padding-percentage-base 50px;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    margin-top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  background: $white;
  text-align: center;
  max-width: $grid-xl-no-gutter;
  width: 100%;
}

@mixin more-space-between-plugins() {
  header {
    margin-bottom: $padding-base;
  }

  .grid > * + * {
    margin-top: $padding-reduced-double;
  }
}

// In case an image has to be bigger than the grid, but still inside the grid
@mixin full-width-mobile-image {
  border-radius: 0;
  margin-left: -$padding-reduced;
  width: calc(100% + #{$padding-reduced-double});
}

@mixin within-grid {
  @include mq-s {
    max-width: $grid-s;
  }

  @include mq-m {
    max-width: calc(#{$grid-m} - #{$gutter});
  }

  @include mq-l {
    max-width: calc(#{$grid-l} - #{$gutter});
  }

  @include mq-xl {
    max-width: $grid-xl-no-gutter;
  }

  max-width: $grid-xs;
  width: 100%;
}

// Custom breakpoints mixin

@mixin mq-s-m() {
  @media (max-width: #{$screen-s-m}) {
    @content;
  }
}

@mixin mq-m-l() {
  @media (max-width: #{$screen-m-l}) {
    @content;
  }
}
