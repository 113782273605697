//
// Generic slick slider styles
//

.slick-arrow {
  font-size: 0;
  z-index: 2;

  &:before {
    font-size: 18px;
  }
}

.slick-prev:before,
.slick-next:before {
  @include font-size(20);
  font-family: "simple-line-icons";
  color: darken($light-grey, 10%);
}

.slick-prev:before {
  content: "\e605";
}

.slick-next:before {
  content: "\e606";
}

//
// Normal slick slider styles
//

.slick-slideshow-arrow {
  .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: none !important;
  }

  .slick-prev {
    left: 0;
  }

  .slick-next {
    right: 0;
  }
}

//
// Custom (or paginated) slick slider styles
//

.slick-slide {
  outline: none; // not an input, so this is ok

  .wysiwyg .btn {
    margin-top: $padding-reduced-half;
  }
}

.slick-paginated {
  .widget-image {
    margin-bottom: $padding-reduced-quadruple;
  }
}

.slick-paginator {
  @include mq-l-max {
    top: 38vw;
  }

  @include mq-m-max {
    top: 42vw;
  }

  position: absolute;
  width: 100%;
  max-width: 500px;
  height: 40px;
  top: 510px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;

  &__status {
    @include font-size(18);
    display: inline;
    font-family: $primary-font;
    line-height: 40px;
    height: 100%;
    color: $border-grey;

    .current {
      color: $title-grey;
      margin-right: 4px;
    }
  }

  &__dots {
    position: absolute;
    width: 350px;
    height: 5px;
    top: 50%;
    left: 45%;
    transform: translate(-50%, -45%);
    border-radius: $padding-reduced;
    overflow: hidden;

    // Blame Carlo for specificity here!
    // TODO if we will have time, rewrite this part using BEM
    ul {
      height: 100%;
      overflow: hidden;
    }

    li {
      display: inline-block;
      height: 100%;
      // width is given using js

      &.slick-active button {
        background-color: $limmi-blue;
      }
    }

    button {
      background-color: $light-grey;
      font-size: 0;
      width: 100%;
      height: 100%;
      vertical-align: top;

      &:hover {
        background-color: $border-grey;
      }
    }
  }

  &__arrows {
    display: inline;
    float: right;
    line-height: 2.3; // to align the arrows icons vertically
  }

  .slick-arrow {
    padding: calc($padding-reduced / 2);
    background: transparent;
  }
}

// Slider style in mobile

@include mq-s-max() {
  // this is an exception to the rule `.grid .grid`
  .grid .slick-slide .grid {
    width: $grid-s;
  }

  .slick-slide {
    > div {
      padding: 0;
    }

    > .widget-image .media {
      border-radius: 0;
      width: 100%;
    }

    .wysiwyg .btn {
      margin-top: 0;
    }

    header {
      margin-bottom: $padding-base-half;
    }
  }

  .slide-image-text {
    width: calc(
      100% + 25px
    ); // This 25px has to be added to left padding of 15px to reach 40px - padding is needed to keep the slider structure
    margin-left: -$padding-reduced;
    padding-right: 0;

    img {
      width: 100%;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: $padding-reduced 0 0 0;
    }
  }

  .slick-paginator {
    top: 101vw;

    &__arrows,
    &__status {
      display: none;
      visibility: hidden;
    }

    &__dots {
      position: relative;
      width: 100%;
      height: auto;
      top: auto;
      left: auto;
      transform: none;
      border-radius: 0;
      text-align: center;

      li {
        width: 18px !important; // because width is given by js
        height: 18px;
        margin: 0 calc($padding-reduced / 4);

        &.slick-active button {
          box-shadow: inset 0 0 0 2px $limmi-blue;
        }

        button {
          border-radius: 20px;
          background: $white;
          box-shadow: inset 0 0 0 2px $border-grey;
          padding: 0;
        }
      }
    }
  }

  .slick-paginator--mobile {
    position: relative;
    top: 0;
    margin-bottom: $padding-reduced-half;

    .slick-paginator__dots li {
      margin: 0 2px;
    }
  }

  .slick-paginated {
    .widget-image {
      margin-bottom: $padding-reduced;
    }
  }

  .slick-teaser {
    width: 100%;
    margin: 0;

    &-paginator {
      position: relative;
      top: auto;
    }

    .widget-text__teaser {
      height: auto;
      min-height: 260px;
      margin: $padding-reduced-half 0 $padding-reduced 0;
      padding: $padding-reduced-half $padding-reduced;
      overflow: visible;
    }
  }

  .widget-image__description .sa-link {
    margin: 0;
  }
}

.slick-list {
  /* This important is necressary due to the fact that the elements gets an inline height of 400px */
  min-height: $teaser-small-height + $gutter !important;
}
