.footer {
  margin-top: 2px;

  .widget-section {
    @include mq-s-max() {
      padding-top: $padding-reduced;
      padding-bottom: $padding-reduced;
    }
    padding-top: $padding-base-section * 2;
    padding-bottom: $padding-base-section * 2;
  }

  &__element {
    text-align: left;

    p {
      @include footer-text;
      margin: $padding-reduced-half 0;
    }

    strong {
      font-weight: $bold;
    }
  }

  &__social-img {
    width: 40px;
    height: 40px;
    position: relative;
    display: inline-block;
    margin: $padding-reduced-half $padding-reduced-quarter;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}

@include mq-s-max() {
  .footer__social-img {
    margin: $padding-reduced-half $padding-reduced-half $padding-reduced-half 0;
  }
}
