//* scss-compile-options: --precision 8

////////////////////////////////////////////////////////////////////////////////
// The Grid
////////////////////////////////////////////////////////////////////////////////

// 0    $mq-xs   |    $mq-s    |    $mq-m    |    $mq-l     |    $mq-xl
// |-------------|-------------|-------------|--------------|-------------- ...
// |<-----XS---->|<-----S----->|<-----M----->|<------L----->!<------XL----- ...

////////////////////////////////////////////////////////////////////////////////
// The gutter
////////////////////////////////////////////////////////////////////////////////

$gutter: 30px !default;

$gutter-double: $gutter * 2;
$gutter-triple: $gutter * 3;
$gutter-quadruple: $gutter * 4;
$gutter-half: calc($gutter / 2);
$gutter-quarter: calc($gutter / 4);
$gutter-three-quarter: calc($gutter * 3 / 4);

////////////////////////////////////////////////////////////////////////////////
// Screen size
////////////////////////////////////////////////////////////////////////////////

$screen-xs: 320px;
$screen-s: 720px;
$screen-m: 1024px;
$screen-l: 1330px;
$screen-xl: 1440px;

////////////////////////////////////////////////////////////////////////////////
// Grid size
////////////////////////////////////////////////////////////////////////////////

$grid-xs: calc(100% - 10px);
$grid-s: calc(100% - 10px);
$grid-m: 100%;
$grid-l: 90%;
$grid-xl-no-gutter: 1170px;
$grid-xl: $grid-xl-no-gutter + $gutter;

////////////////////////////////////////////////////////////////////////////////
// Number of columns
////////////////////////////////////////////////////////////////////////////////

$xs-grid: 12 !default; // Number of columns in the extra small grid
$s-grid: 12 !default; // Number of columns in the small grid
$m-grid: 12 !default; // Number of columns in the medium grid
$l-grid: 12 !default; // Number of columns in the large grid
$xl-grid: 12 !default; // Number of columns in the extra large grid
