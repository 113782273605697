/*===============================
=            Choices            =
===============================*/

$choices-selector: "choices" !default;
$choices-guttering: 24px !default;
$choices-border-radius: 3px !default;
$choices-border-radius-item: 20px !default;
$choices-bg-color: $white !default;
$choices-bg-color-disabled: #ddd !default;
$choices-bg-color-dropdown: $white !default;
$choices-text-color: $text-grey !default;
$choices-keyline-color: $border-grey !default;
$choices-primary-color: $dove-blue !default;
$choices-disabled-color: #666 !default;
$choices-highlight-color: $choices-primary-color !default;
$choices-button-icon-path: "../../icons" !default;
$choices-button-dimension: 8px !default;
$choices-button-offset: 8px !default;
$negative-choices-button-offset: -8px !default;

.#{$choices-selector} {
  position: relative;
  color: $text-grey;

  &:focus {
    outline: none;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.is-disabled {
    .#{$choices-selector}__inner,
    .#{$choices-selector}__input {
      background-color: $choices-bg-color-disabled;
      cursor: not-allowed;
      user-select: none;
    }

    .#{$choices-selector}__item {
      cursor: not-allowed;
    }
  }
}

.#{$choices-selector}[data-type*="select-one"] {
  cursor: pointer;

  .#{$choices-selector}__input {
    display: block;
    width: 100%;
    padding: $padding-reduced-half $padding-reduced;
    border-bottom: 1px solid $choices-keyline-color;
    background-color: #fff;
    margin: 0;
  }

  .#{$choices-selector}__button {
    padding: 0;
    background-size: 8px;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -10px;
    margin-right: 25px;
    height: 20px;
    width: 20px;
    border-radius: 10em;
    opacity: 0.5;

    &:hover,
    &:focus {
      opacity: 1;
    }

    &:focus {
      box-shadow: 0 0 0 2px $choices-highlight-color;
    }
  }

  &:after {
    content: "\e604";
    font-family: "simple-line-icons";
    font-size: 14px;
    position: absolute;
    pointer-events: none;
    line-height: 1;
    right: $padding-reduced;
    top: 50%;
    transform: translateY(-50%);
  }

  &[dir="rtl"] {
    &:after {
      left: 11.5px;
      right: auto;
    }

    .#{$choices-selector}__button {
      right: auto;
      left: 0;
      margin-left: 25px;
      margin-right: 0;
    }
  }
}

.#{$choices-selector}[data-type*="select-multiple"],
.#{$choices-selector}[data-type*="text"] {
  .#{$choices-selector}__inner {
    cursor: text;
  }

  .#{$choices-selector}__button {
    position: relative;
    display: inline-block;
    margin-top: 0;
    margin-right: calc($negative-choices-button-offset / 2);
    margin-bottom: 0;
    margin-left: $choices-button-offset;
    border-left: 1px solid darken($choices-primary-color, 10%);
    background-size: $choices-button-dimension;
    width: $choices-button-dimension;
    line-height: 1;
    opacity: 0.75;

    &:hover,
    &:focus {
      opacity: 1;
    }
  }
}

.#{$choices-selector}__inner {
  @include mq-s-max {
    @include font-size(14);
  }

  @include font-size(18);
  display: inline-block;
  vertical-align: top;
  width: 100%;
  background-color: $choices-bg-color;
  padding: 0;
  border: 1px solid $choices-keyline-color;
  border-radius: $choices-border-radius;
  height: 50px;
  overflow: hidden;

  .is-focused &,
  .is-open & {
    border-color: darken($choices-keyline-color, 15%);
  }

  .is-flipped.is-open & {
    border-radius: 0 0 $choices-border-radius $choices-border-radius;
  }
}

.#{$choices-selector}__list {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.#{$choices-selector}__list--single {
  display: inline-block;
  width: 100%;

  [dir="rtl"] & {
    padding-right: 4px;
    padding-left: 16px;
  }

  .#{$choices-selector}__item {
    @include mq-s-max {
      padding: 14px $padding-reduced;
    }

    padding: $padding-reduced-half $padding-reduced;
    padding-right: 0;
    width: calc(100% - #{$padding-reduced-double});
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.#{$choices-selector}__list--multiple {
  display: inline;

  .#{$choices-selector}__item {
    display: inline-block;
    vertical-align: middle;
    border-radius: $choices-border-radius-item;
    padding: 4px 10px;
    margin-right: 3.75px;
    margin-bottom: 3.75px;
    background-color: $choices-primary-color;
    border: 1px solid darken($choices-primary-color, 5%);
    color: #fff;
    word-break: break-all;

    &[data-deletable] {
      padding-right: 5px;
    }

    [dir="rtl"] & {
      margin-right: 0;
      margin-left: 3.75px;
    }

    &.is-highlighted {
      background-color: darken($choices-primary-color, 5%);
      border: 1px solid darken($choices-primary-color, 10%);
    }

    .is-disabled & {
      background-color: darken($choices-disabled-color, 25%);
      border: 1px solid darken($choices-disabled-color, 35%);
    }
  }
}

.#{$choices-selector}__list--dropdown {
  display: none;
  z-index: 1;
  position: absolute;
  width: 100%;
  background-color: $choices-bg-color-dropdown;
  border: 1px solid $choices-keyline-color;
  top: 100%;
  margin-top: -1px;
  border-bottom-left-radius: $choices-border-radius;
  border-bottom-right-radius: $choices-border-radius;
  overflow: hidden;
  word-break: break-all;
  border-radius: 3px;
  box-shadow: $shadow-l;

  &.is-active {
    display: block;
  }

  .is-open & {
    border-color: darken($choices-keyline-color, 15%);
  }

  .is-flipped & {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: -1px;
    border-radius: 0.25rem 0.25rem 0 0;
    box-shadow: $shadow-s;
  }

  .#{$choices-selector}__list {
    position: relative;
    max-height: 300px;
    overflow: auto;
    -webkit-overflow-scrolling: touch; //sass-lint:disable-line no-vendor-prefixes
    will-change: scroll-position;
  }

  .#{$choices-selector}__item {
    position: relative;
    padding: 10px $padding-reduced;

    [dir="rtl"] & {
      text-align: right;
    }
  }

  .#{$choices-selector}__item--selectable {
    @media (min-width: 640px) {
      &:after {
        content: attr(data-select-text);
        opacity: 0;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }

      [dir="rtl"] & {
        text-align: right;
        padding-right: 10px;

        &:after {
          right: auto;
          left: 10px;
        }
      }
    }

    &.is-highlighted {
      background-color: $dove-blue;
    }
  }
}

.#{$choices-selector}__item {
  cursor: default;
}

.#{$choices-selector}__item--selectable {
  cursor: pointer;
}

.#{$choices-selector}__item--disabled {
  cursor: not-allowed;
  user-select: none;
  opacity: 0.5;
}

.#{$choices-selector}__heading {
  padding: 10px;
  border-bottom: 1px solid lighten($choices-keyline-color, 10%);
  color: lighten(#333, 30%);
}

.#{$choices-selector}__button {
  text-indent: -9999px;
  -webkit-appearance: none; //sass-lint:disable-line no-vendor-prefixes
  appearance: none;
  border: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.#{$choices-selector}__input {
  display: inline-block;
  vertical-align: baseline;
  background-color: mix(#000, #fff, 2.5%);
  margin-bottom: 5px;
  border: 0;
  border-radius: 0;
  max-width: 100%;
  padding: 4px 0 4px 2px;

  &:focus {
    outline: 0;
  }

  [dir="rtl"] & {
    padding-right: 2px;
    padding-left: 0;
  }
}

.#{$choices-selector}__placeholder {
  opacity: 0.5;
}

/*=====  End of Choices  ======*/
