//
html {
  @include mq-m-max {
    @include font-size(16);
  }

  @include mq-s-max {
    @include font-size(16);
  }
}

body {
  @include font-size(16);
  background: $white;
  position: relative;
  font-family: $secondary-font;
  line-height: $line-height;
  padding-bottom: 90px;
}

img {
  vertical-align: bottom;
  max-width: 100%;
  height: auto;
}

a {
  font-family: $secondary-font;
  text-decoration: none;
  color: $limmi-blue;

  &:visited {
    color: $limmi-blue;
  }

  &:hover {
    opacity: 0.5;
  }
}

b,
strong {
  font-weight: bold;
}

i,
em {
  font-style: italic;
}

p {
  font-family: $secondary-font;
  font-weight: 300;
}

hr {
  width: 100%;
  opacity: 0.3;

  &.widget-horizontal-divider {
    @include mq-s-max {
      margin-bottom: 0;
      opacity: 0;
    }

    margin-top: $padding-base-section-content;
    margin-bottom: $padding-base-section-content;

    &--separator {
      opacity: 0;
    }
  }
}

main {
  display: block; // For IE11
}

////////////////////////////////////////////////////////////////////////////////
// Utilities
////////////////////////////////////////////////////////////////////////////////

.align-center {
  text-align: center;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.margin-bottom {
  margin-bottom: $padding-reduced;
}

.block {
  width: 100%;
  display: block;
}

.no-scroll {
  overflow: hidden;
}

////////////////////////////////////////////////////////////////////////////////
// General div
////////////////////////////////////////////////////////////////////////////////

.page {
  position: relative;
  margin: 0 auto;

  &-container {
    padding-top: $header-desktop-h;

    &--brustzentrum,
    &--adipositaszentrum {
      padding-top: $header-desktop-no-top-menu-h;
    }
  }

  &-homepage--adipositaszentrum {
    .widget-image__intro-caption {
      @include mq-l-max() {
        width: 60%;
      }
      @include mq-m-max() {
        width: 70%;
      }
      right: 0;
      width: 55%;

      h4 {
        @include font-size(28);
        font-weight: $regular;

        .text--big {
          @include font-size(48);
        }

        @include mq-l-max() {
          @include font-size(20);

          .text--big {
            @include font-size(36);
          }
        }

        @include mq-s-max() {
          line-height: 1.5;

          .text--big {
            line-height: 1.5;
          }
        }
      }
    }
  }
}

.column-flex {
  display: flex;
}
