////////////////////////////////////////////////////////////////////////////////
// Generic grid utilites
////////////////////////////////////////////////////////////////////////////////

// This has to be added to jessica -- Simone

@mixin no-gutter($left: 0, $right: 0) {
  padding-left: $left !important;
  padding-right: $right !important;
}

// Apply these classes to the columns to remove the gutter in one or both sides

.no-gutter {
  @include no-gutter;
}

.no-gutter-left {
  @include no-gutter(0, auto);
}

.no-gutter-right {
  @include no-gutter(auto, 0);
}

// Apply this class to the columns to make the element inside it grow in height
// like the items on the same row.

.vertical-grow {
  > * {
    flex: 1;
  }
}

// Apply this class to the columns to make the element go to the right

.right {
  margin-left: auto;
}

// Apply this class to the columns to make the element go to the center

.centered {
  margin: 0 auto;
}

// Apply this class to the columns to center text

.centered--text {
  text-align: center;
}

// Apply this class to a row to center align his children.

.row--centered {
  justify-content: center;
}

////////////////////////////////////////////////////////////////////////////////
// Grid utilities for media queries
////////////////////////////////////////////////////////////////////////////////

// NOTE: ALL THE CLASSES WILL BE APPLIED FROM THE MEDIA QUERY UP.
// YOU NEED TO OVERWRITE THE CLASS IF YOU WANT TO CHANGE HIS BEHAVIOUR IN SOME
// BIGGER MEDIA QUERY.

// Apply this class to the columns to show it ONLY in some media queries.
// The columns will be hidden by default.

.visible-xs,
.visible-s,
.visible-m,
.visible-l,
.visible-xl {
  display: none;
}

@include mq-xs {
  .hidden-xs {
    display: none !important;
  }

  .visible-xs {
    display: flex !important;
  }

  .no-gutter-xs {
    padding: 0 !important;
  }

  .no-gutter-left-xs {
    padding-left: 0 !important;
  }

  .no-gutter-right-xs {
    padding-right: 0 !important;
  }

  .gutter-xs {
    padding-left: calc($gutter / 2) !important;
    padding-right: calc($gutter / 2) !important;
  }

  .vertical-grow-xs {
    > * {
      flex: 1;
    }
  }

  .right-xs {
    margin-left: auto;
  }

  // Use .no-margin to undo the .right behaviour

  .no-margin-xs {
    margin: 0;
  }

  .row--centered-xs {
    justify-content: center;
  }
}

@include mq-s {
  .hidden-s {
    display: none !important;
  }

  .visible-s {
    display: flex !important;
  }

  .no-gutter-s {
    padding: 0 !important;
  }

  .no-gutter-left-s {
    padding-left: 0 !important;
  }

  .no-gutter-right-s {
    padding-right: 0 !important;
  }

  .gutter-s {
    padding-left: calc($gutter / 2) !important;
    padding-right: calc($gutter / 2) !important;
  }

  .vertical-grow-s {
    > * {
      flex: 1;
    }
  }

  .right-s {
    margin-left: auto;
  }

  // Use .no-margin to undo the .right behaviour

  .no-margin-s {
    margin: 0;
  }

  .row--centered-s {
    justify-content: center;
  }
}

@include mq-m {
  .hidden-m {
    display: none !important;
  }

  .visible-m {
    display: flex !important;
  }

  .no-gutter-m {
    padding: 0 !important;
  }

  .no-gutter-left-m {
    padding-left: 0 !important;
  }

  .no-gutter-right-m {
    padding-right: 0 !important;
  }

  .gutter-m {
    padding-left: calc($gutter / 2) !important;
    padding-right: calc($gutter / 2) !important;
  }

  .vertical-grow-m {
    > * {
      flex: 1;
    }
  }

  .right-m {
    margin-left: auto;
  }

  // Use .no-margin to undo the .right behaviour

  .no-margin-m {
    margin: 0;
  }

  .row--centered-m {
    justify-content: center;
  }
}

@include mq-l {
  .hidden-l {
    display: none !important;
  }

  .visible-l {
    display: flex !important;
  }

  .no-gutter-l {
    padding: 0 !important;
  }

  .no-gutter-left-l {
    padding-left: 0 !important;
  }

  .no-gutter-right-l {
    padding-right: 0 !important;
  }

  .gutter-l {
    padding-left: calc($gutter / 2) !important;
    padding-right: calc($gutter / 2) !important;
  }

  .vertical-grow-l {
    > * {
      flex: 1;
    }
  }

  .right-l {
    margin-left: auto;
  }

  // Use .no-margin to undo the .right behaviour

  .no-margin-l {
    margin: 0;
  }

  .row--centered-l {
    justify-content: center;
  }
}

@include mq-xl {
  .hidden-xl {
    display: none !important;
  }

  .visible-xl {
    display: flex !important;
  }

  .no-gutter-xl {
    padding: 0 !important;
  }

  .no-gutter-left-xl {
    padding-left: 0 !important;
  }

  .no-gutter-right-xl {
    padding-right: 0 !important;
  }

  .gutter-xl {
    padding-left: calc($gutter / 2) !important;
    padding-right: calc($gutter / 2) !important;
  }

  .vertical-grow-xl {
    > * {
      flex: 1;
    }
  }

  .right-xl {
    margin-left: auto;
  }

  // Use .no-margin to undo the .right behaviour

  .no-margin-xl {
    margin: 0;
  }

  .row--centered-xl {
    justify-content: center;
  }
}
